import React, {ChangeEvent} from "react";

interface ItemProps {
    name: string
    value: string
    placeholder: string
    required: boolean
    onChange(event: ChangeEvent<HTMLInputElement>)
    t: any
}

export const FormDataBookingItem = ({name, value, placeholder, required, onChange,t}: ItemProps) => {

    let maxLength;

    switch(name){
        case 'name': maxLength = 20;
                     break;
        case 'surname': maxLength = 30;
                        break;
        case 'telephone': maxLength = 15;
                          break;
        case 'email': maxLength = 35;
                      break;
        case 'additionalInformation': maxLength = 100;
                                      break;
        default: maxLength = 20;
    }

    return (
        <>
            <div className="input-group mb-3">
                <input
                    name={name}
                    value={value}
                    type="text"
                    className="form-control"
                    placeholder={name === 'additionalInformation' ? placeholder+`  (${t("additionalInfo")})` :placeholder}
                    aria-label={name}
                    aria-describedby="addon-wrapping"
                    onChange={onChange}
                    required={required}
                    maxLength={maxLength}
                />
            </div>

            
        </>
    )
}
