import React, {Component} from "react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar, {DayCellContentArg, ViewApi } from "@fullcalendar/react"
import {IHora} from '../models/IHora'
import './CalendarAvailable.css'

interface Props {
    disabledDays
    horasIda
    clickDateHandler(arg: {
        date: Date
        dateStr: string
        allDay: boolean
        resource?: any
        dayEl: HTMLElement
        jsEvent: MouseEvent
        view: ViewApi
    }): void
    handleDayRender?(info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }): void
}

export default class CalendarAvailable extends Component<Props, object> {

    pRef = React.createRef()

    handleDayRender = (info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }) => {
        const {disabledDays, horasIda} = this.props
        const now = new Date()
        info.date.setHours(23, 59, 59, 999)
        const day = new Date(info.date)

        if (day<now) return
        let othermonth = false
        if (info.el.className.includes('fc-disabled-day') || info.el.className.includes('fc-other-month')) othermonth = true

        const disabledDay =  disabledDays?.find(value => value.day === info.date.getDate() && value.month === info.date.getMonth() && value.year === info.date.getFullYear()
        )

        let color = '#eaffdd',
        backColor = backgreen// ,
        // content = T('available')

        if (disabledDay && disabledDay.hours && disabledDay.hours.length>0) {
            const hoursFiltered = filterHours(horasIda, disabledDay.hours)
            color = hoursFiltered.length === horasIda.length? backred : backorange
            backColor = 'red'
            // content = hoursFiltered.length === this.props.stretch.horasIda.length? T('notAvailable') : T('parcialAvailable')
        }
        const opacity=othermonth? 0.3: 1
        info.el.innerHTML = renderDay2(color, backColor, '', opacity)
    }

    renderDayCell = (dayCellContent: DayCellContentArg) => {
        const {
            date,
            isOther,
        } = dayCellContent
        
        const {disabledDays, horasIda} = this.props
        const now = new Date()
        date.setHours(23, 59, 59, 999)
        const day = new Date(date)

        if (day<now) return
        let othermonth = isOther

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())

        let color = '#eaffdd',
            backColor = backgreen// ,
        // content = T('available')

        if (disabledDay && disabledDay.hours && disabledDay.hours.length>0) {
            const hoursFiltered = filterHours(horasIda, disabledDay.hours)
            
            color = hoursFiltered.length === horasIda.length? backred : backorange
            backColor = 'red'
            // content = hoursFiltered.length === this.props.stretch.horasIda.length? T('notAvailable') : T('parcialAvailable')
        }
        const opacity=othermonth? 0.3: 1
        return renderDay(color, backColor, '', opacity)
    }
    
    handleDayCellClassNames = (dayCellContent: DayCellContentArg) => {
        const {
            date,
            isPast,
            isToday,
        } = dayCellContent
        
        const {disabledDays, horasIda} = this.props
        date.setHours(23, 59, 59, 999)
        let className = []
        if (isPast) {
            className.push('color-isPast')        
        }

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())
        
        
        
        if (isToday) {
            if (disabledDay && disabledDay.hours && disabledDay.hours.length>0){
                const hoursFiltered = filterHours(horasIda, disabledDay.hours)
                
                if (hoursFiltered.length === horasIda.length) {
                    className.push('color-isDisabled')   
                } else {
                    className.push('color-isPartialDisabled')  
                }
                 
            } else if(disabledDay && disabledDay.hours && disabledDay.hours.length === 0){
                className.push('color-isDisabled')                    
            }else {
                className.push('day-cell-enabled-background')
            }          
        }
        
        if(!isPast){
            if (disabledDay && disabledDay.hours && disabledDay.hours.length>0) {
                const hoursFiltered = filterHours(horasIda, disabledDay.hours)
                if (hoursFiltered.length === horasIda.length) {
                   // className.push('day-cell-disabled-background')
                    className.push('color-isDisabled')    
                } else if(hoursFiltered.length > 0){ 
                    className.push('color-isPartialDisabled')        
                    //className.push('day-cell-partial-background')
                }else { // Comportamiento en caso de que haya una hora en deshabilitada que no exista en las horas del servicio, por lo que ese dia tenga el mismo horario que el resto de dias
                    className.push('day-cell-enabled-background')
                }
            }else if(disabledDay && disabledDay.hours && disabledDay.hours.length === 0){
                className.push('color-isDisabled')         
            } else {
                className.push('day-cell-enabled-background')
            }
        }

        return className
    }
    
    render() {

        let idioma= localStorage.getItem('idioma');
        if(idioma == null){
            const userLang = window.navigator.language;
            idioma = userLang.substring(0,2);
        }
     
        const {clickDateHandler} = this.props
        return (
            <>
                <FullCalendar
                    // dayRender={handleDayRender}
                    // dayCellContent={this.renderDayCell}
                    dayCellClassNames={this.handleDayCellClassNames}
                    firstDay={1}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView={"dayGridMonth"}
                    dateClick={clickDateHandler}
                    selectable={true}
                    locale={idioma}
                    buttonText={{ today: idioma === 'es' ? 'Hoy' : 'Today' }}
                />
            </>
        )
    }
}

/*
  método para filtar las horas deshabilitadas en un IDay.hours ya que se puede dar el caso que alguna se haya eliminado
  en el servicio. Las que no estén en horasIda se eliminan
   */
export const filterHours = (horasIda: IHora[], hoursSelected: IHora[]) => {
    const result = hoursSelected.reduce((previous, hora) => {
        if (horasIda?.find((horaIda) => horaIda.hours === hora.hours && horaIda.minutes === hora.minutes)) {
            previous.push(hora)
        }
        return previous
    }, [])
    return result
}

const renderDay = (backgroundColor, color, content, opacity) => (
    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', backgroundColor: backgroundColor, opacity: opacity, height: '100%'}} >
        <p style={{color: color}}>{content}</p>
    </div>
)
const renderDay2 = (backgroundColor, color, content, opacity) =>
    `<div style="display: flex flex-direction: row justify-content: center align-items: center background-color: ${backgroundColor} opacity: ${opacity} height: 100%" >
    <p style="color: ${color}">${content}</p>
  </div>`

const backred= 'rgba(255, 0, 0, 0.2)'
const backgreen= 'rgb(0, 255, 0, 0.2)'
const backorange= '#ffe9ce'
