const baseURL = process.env.REACT_APP_baseURL
const prefix = process.env.REACT_APP_PREFIX;
const baseURLTicando = process.env.REACT_APP_baseURLTicando

export const deleteItemFromCart2 = async(numero: string, authorization: string) => {
    try{ // Control de errores hecho, pero se mete en el then, se controla en la llamada con el else
        const request = await fetch(`${baseURLTicando}/carttoken/${numero}`, {
            headers: {
                'Content-Type': 'application/json',
                "x-provider": "creaticket",
                "Authorization": `Bearer ${authorization}`
    
            },
            credentials: 'include',
            method: 'DELETE'
        });
        return request.json();
    }catch(e){
        console.log('catch delete')
        throw e;
    }
}

export const deleteCart = async (uuid: string, authorization: string) => {
    try{
        const request = await fetch(
            `${baseURLTicando}/carttoken/cart/${uuid}`,
            {
                method: 'DELETE',
                headers: { 
                    'Content-Type': 'application/json',
                    "x-provider": "creaticket",
                    "Authorization": `Bearer ${authorization}`
                },
                credentials: 'include',
            }
        )
        return request.json()
    }catch(e){
        console.log('Delete cart')
    }
}

export const getCart = async(uuid: string, authorization) => {
    try{
        const request = await fetch(
            `${baseURLTicando}/carttoken/cart/${uuid}`,
            {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    "x-provider": "creaticket",
                    "Authorization": `Bearer ${authorization}`
                },
                credentials: 'include',
            }
        )
       // console.log(request.status)
       // console.log(res)
        return request.json()
    }catch(e){
        console.log('GETCART')
    }

}

export const getPaymentToken = async (body: string, publicToken: string) => {
    const request = await fetch(`${baseURL}${prefix}/payment`, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({body, idTicando: publicToken}),
        method: 'POST'
    });
    const res = request.json()
    return res;
}

export const getClientData = async(publicToken: string) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getClientebyPUBLICAPIKEY`, {
            headers: { 'Content-Type': "application/json", authorization: `Bearer ${publicToken}` },
            credentials: 'include',
            method: 'GET'
        })
        if(request.status >= 400){
            throw new Error()
        }
        // return await baseRequest<Pasarela[]>(`getPasarelaPago?id=${id}`)
        // TODO codigo duro para pruebas
        return request.json(); 
        // return [{pasarelaPago: 'MULTISAFEPAY'}]  
        // return [{pasarelaPago: 'TRUSTMYTRAVEL'}] 
    }catch(e){
        throw e;
    }

}

export const getPublic_Key = async(publicToken) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getPublic_Key`,{
            headers: {'Content-Type': 'application/json', 'Authorization': `Bearer ${publicToken}`},
            method: 'GET',
            credentials: 'include'
        })
        return request.json()

    }catch(e){
        throw e;
    }
}
