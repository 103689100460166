import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router";
import { SearchExperience } from "./SearchExperience";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

interface Props {
    onCartClick():void
    publicToken?: string
    isMain?: boolean
    busqueda?: string
    setBusqueda?: any
    setLoaded?: any
}

export const HeaderView = ({onCartClick, publicToken, isMain, busqueda, setBusqueda, setLoaded}: Props) => {
    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }
    const history = useHistory()

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]); 
    
    return (
        <header className="book-embed-nav with-padding respond-print-hide">
            <div className="ben-right">
                <ul className="ben-left">
                    <li className="test-flow-node-breadcrumb" aria-label="Home"
                        title="Home">
                        <a onClick={() => {history.push(`/${publicToken}/main/`)}}>
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-home"
                                className="icon-home" width="13" height="13" viewBox="0 0 13 13"
                            >
                                <path
                                    d="M5.63.28l-5,3.54A1.61,1.61,0,0,0,0,5.15v6.52A1.32,1.32,0,0,0,1.34,13H4.46V9.66a2.06,2.06,0,0,1,4.12,0h0V13h3.08A1.31,1.31,0,0,0,13,11.72V5.15a1.66,1.66,0,0,0-.68-1.33L7.41.28A1.57,1.57,0,0,0,5.63.28Z"/>
                            </svg>
                        </a>
                    </li>
                    <li className="ben-flyout-wrap">
                        <button className="ben-item ben-secure-button fh-green" type="button"
                                aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                 id="icon-svg-lock" className="icon-svg-lock" width="11" height="14"
                                 viewBox="0 0 11.02 14">
                                <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}/>
                                <path
                                    d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"/>
                            </svg>
                            {t("securePayment")}
                        </button>

                    </li>

                    <li className="ben-flyout-wrap">

                        <div>
                            <a className="ben-item ben-cart-button fade-in-up" type="button"
                               aria-expanded="false" onClick={() => onCartClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-cart"
                                     className="icon-cart" width="27" height="23" viewBox="0 0 27 23" style={{fill: 'white'}}
                                >
                                    <path
                                        d="M24.47,16.68H7.12l-3.74-14H.08V.21H5.42l3.74,14H22.31l1.58-8.48H11.25V3.22H26.92Z"/>
                                    <path d="M23,20.59a2.2,2.2,0,1,0-2.2,2.2A2.19,2.19,0,0,0,23,20.59Z"/>
                                    <path d="M12.19,20.59A2.2,2.2,0,1,0,10,22.79,2.19,2.19,0,0,0,12.19,20.59Z"/>
                                </svg>
                                <span className="respond-handhelds-hide">{t("cart")}</span>
                                <span className="respond-desktop-hide"/>
                            </a>
                        </div>


                    </li>
                    </ul>
                    {  
                            isMain &&
                            <>
                            <li style={{textAlign: 'right'}}>
                                <SearchExperience busqueda={busqueda} setBusqueda={setBusqueda} setLoaded={setLoaded}/>
                            </li>
                            <button className="btn btn-info" style={{marginRight:'110px', backgroundColor:'#0a6ece'}}>
                            <FontAwesomeIcon icon={faSearch}/>
                            </button>
                            </>
                        }
                
            </div>

        </header>
    )
}
