import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    busqueda?: string
    setBusqueda?: any
    setLoaded?: any
}

export const SearchExperience = ({busqueda, setBusqueda, setLoaded}: Props) => {
    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        idioma='es';
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]);
 
    const handleChange = (e) => {
        setLoaded(false)
        setBusqueda(e.target.value)
    }
   
    return (
        <div className="containerInput">
            <div className="row">
                <div className="col-md-6">


        <input style={{width: '300px',height: '10px',paddingTop:'18px', paddingLeft: '10px', paddingBottom: '18px'}}
          className="form-control inputBuscar"
          value={busqueda}
          placeholder={t("searchExperience")}
          onChange={handleChange}
        />
                        </div>
            </div>
      </div>
    )
}