import Price from '../models/Price'
import {Tax} from '../models/Tax'
import { Vis } from "models/Vis";
import { primeraLetraMayuscula } from "Modules/Util";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    prices: Price[]
    money: any
    tax: Tax
    visitas: Vis
    quitarVisitantePrices(event: any): void
    addVisitantePrices(event: any): void
    limiteCapacidad?: boolean
}

export const PricesView = ({prices, money, tax, visitas, quitarVisitantePrices, addVisitantePrices, limiteCapacidad}: Props) => {
    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]); 
    
    if (!prices || prices.length === 0) {
        return null
    }

    const reducer = (accumulator, curr) => accumulator + curr;


    let pasajerosTotal = 0;
    if(visitas.vis.length !== 0){
        pasajerosTotal = visitas.vis.slice().reduce(reducer)
    }


    return (
        <>
            <h3 className="preciosHeading">
                {t("prices")}
            </h3>

            {
                prices.map((precio) => {
                    const precioViaje = precio.price + precio.price * tax.value
                    const index = prices.indexOf(precio)
                    return (
                        <div className="opciones" key={precio.id}>
                            {
                                precio.passengerCategory.name && precio.price > 0 &&
                                <div className="row row-cols-auto text-left align-items-center">
                                    <div className="col-3">
                                        <div className="row row-cols-auto">

                                            <div className="col">
                                                <strong> {primeraLetraMayuscula(precio.passengerCategory.name)}</strong>
                                            </div>
                                        </div>
                                        <div className="row row-cols-auto">

                                            <div className="col">
                                                {precioViaje.toFixed(2)} {money}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 nopadbado">
                                        {visitas.price[index] && visitas.price[index].toFixed(2)} {money}
                                    </div>
                                    <div className="col-2 nopadbad">
                                        <button className="w-100 btn btn-lg" style={{background:'#244c6d'}} value={index}
                                                onClick={quitarVisitantePrices} disabled={visitas.vis[index] === 0} name="delete"><span style={{color:'white'}}>-</span></button>
                                    </div>
                                    <div className="col-1 nopadbado">
                                        {visitas.vis[index]}
                                    </div>
                                    <div className="col-2 nopadbad">
                                        <button disabled={limiteCapacidad} className="w-100 btn btn-lg" style={{background:'#244c6d'}} value={index}
                                                name="add" onClick={addVisitantePrices}><span style={{color:'white'}}>+</span></button>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })
            }
            {
                limiteCapacidad &&
                <div className="opciones" style={{color:'red'}}>{t("maximumNumber")}{pasajerosTotal}</div>
            }


        </>
    )
}
