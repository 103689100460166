import React from "react";
import FullCalendar, {DayCellContentArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {ViewApi} from '@fullcalendar/common'
import  {filterHours} from './CalendarAvailable'

interface Props {
    index: number | string
    isStretchCollaborator: boolean
    disabledDays
    horasIda
    dayRender?(arg: {
        view: ViewApi;
        date: Date;
        allDay?: boolean;
        el: HTMLElement;
    }): void
    onDateClick: (date: Date, index: number | string, isStretchCollaborator: boolean) => void
}

export class DateSelectView extends React.Component<Props, object> {



    handleDateClick = (event: {
        date: Date;
        dateStr: string;
        allDay: boolean;
        resource?: any;
        dayEl: HTMLElement;
        jsEvent: MouseEvent;
        view: ViewApi;
    }) => {
        this.props.onDateClick(new Date(event.date), this.props.index, this.props.isStretchCollaborator)
    }

    handleEventMouseEnter = (info) => {

    }
    // Esta no se utiliza
    handleDayCellClassNames2 = (dayCellContent: DayCellContentArg) => {
        const {
            date,
            isPast,
            isToday,
        } = dayCellContent

        const {disabledDays, horasIda} = this.props
    
        date.setHours(23, 59, 59, 999)

        if (isPast) return

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())

        let className = []

        if (isToday) {
            if (disabledDay){
                const hoursFiltered = filterHours(horasIda, disabledDay.hours)
                if (hoursFiltered.length === horasIda.length) {
                    className.push('color-disabled')
                } else {
                    className.push('color-partial')
                }

            } else {
                className.push('color-enabled')
            }

        }

        if (disabledDay && disabledDay.hours && disabledDay.hours.length>0) {
            const hoursFiltered = filterHours(horasIda, disabledDay.hours)
            if (hoursFiltered.length === horasIda.length) {
                className.push('day-cell-disabled-background')
            } else {
                className.push('day-cell-partial-background')
            }
        } else {
            className.push('day-cell-enabled-background')
        }
        return className
    }
    handleDayCellClassNames = (dayCellContent: DayCellContentArg) => {
        const {
            date,
            isPast,
            isToday,
        } = dayCellContent
        
        const {disabledDays, horasIda} = this.props

        date.setHours(23, 59, 59, 999)

        let className = []
        if (isPast) {
            className.push('color-isPast')        
        }

        const disabledDay =  disabledDays?.find(value => value.day === date.getDate() && value.month === date.getMonth() && value.year === date.getFullYear())
        
        
        
        if (isToday) {
            if (disabledDay){
                const hoursFiltered = filterHours(horasIda, disabledDay.hours)
                if (hoursFiltered.length === horasIda.length) {
                    className.push('color-disabled') 
                } else {
                    className.push('color-partial')
                }
                 
            } else {
                className.push('color-enabled')
            }
           
        }
        if(!isPast){
            if (disabledDay && disabledDay.hours && disabledDay.hours.length>0) {
                const hoursFiltered = filterHours(horasIda, disabledDay.hours)
                if (hoursFiltered.length === horasIda.length) {
                   // className.push('day-cell-disabled-background')
                    className.push('color-isDisabled')    
                } else if(hoursFiltered.length > 0){ 
                    className.push('color-isPartialDisabled')        
                    //className.push('day-cell-partial-background')
                }else { // Comportamiento en caso de que haya una hora en deshabilitada que no exista en las horas del servicio, por lo que ese dia tenga el mismo horario que el resto de dias
                    className.push('day-cell-enabled-background')
                }
            }else if(disabledDay && disabledDay.hours && disabledDay.hours.length === 0){
                className.push('color-isDisabled')         
            } else {
                className.push('day-cell-enabled-background')
            }
        }

        return className
    }
    render() {
        let idioma= localStorage.getItem('idioma');
        if(idioma == null){
            const userLang = window.navigator.language;
            idioma = userLang.substring(0,2);
        }
        return (
            <>
            <FullCalendar
                buttonText={{today: idioma === 'es' ? 'Hoy' : 'Today'}}
                dayCellClassNames={this.handleDayCellClassNames}
                dateClick={this.handleDateClick}
                firstDay={1}
                handleWindowResize={true}
                // defaultView="dayGridMonth"
                locale= {idioma}
                plugins={[dayGridPlugin, interactionPlugin]}
                selectable={true}
                // dayRender={dayRender}
            />
        </>
        )
    }
}
