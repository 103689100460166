import Moment from 'moment';
import {ITMTModal} from '../../models/ITMTModal'
import handlerErrors from './handlerError'
import Swal from 'sweetalert2';
import { Affiliate, Split_Affiliate } from 'models/Affiliate';
import { getChannels } from 'Request/Request';
import Cart from 'models/Cart';
import * as Sentry from "@sentry/react";
import { t } from 'i18next';

const baseURL = process.env.REACT_APP_baseURL
const prefix = process.env.REACT_APP_PREFIX;

// Control de errores hecho, en el push history mete dos veces publicToken
export const getPasarelaPago = async(publicToken: string) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getPasarelaPago`, {
            headers: { 'Content-Type': "application/json", authorization: `Bearer ${publicToken}` },
            credentials: 'include',
            method: 'GET'
        })
        if(request.status >= 400){
            throw new Error()
        }
        return request.json(); 
    }catch(e){
        throw e;
    }

}

export const getPaymentToken = async (body: string, publicToken: string, pasarelaPago: string, environment?: string) => {
    const request = await fetch(`${baseURL}${prefix}/payment`, {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({body, publicToken: publicToken, pasarelaPago: pasarelaPago, environment: environment}),
        method: 'POST'
    });
    const res = request.json();
    return res;
}

export const getComissionData = async (publicToken: string) => {
    const request = await fetch(`${process.env.REACT_APP_baseURLTicando}/tokenticket/owner`, {
        headers: { 
            'Content-Type': 'application/json',
            "x-provider": "creaticket",
            "Authorization": `Bearer ${publicToken}`
        },
        credentials: 'include',
        method: 'POST'
    });
    const res = request.json();
    return res;
}

export const mountMultisafePayBody = async (order_id: string, amount: number, description: string, url_post: string, url_ok: string, 
    url_ko: string, name: string, surnames: string, phone: string, email: string, additionalInfo: string, money: string, cart: Cart, publicToken: string) => {

    const tickets = [...cart.cartTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorTickets, ...cart.cartCollaboratorPacketTickets]
    let min_expired = 9999999999999; // Una cantidad muy elevada para luego ir comparando y obtener el menor tiempo
    for(let i = 0; i < tickets.length; i++){
        let date_expire_at = new Date(tickets[i].expire_at)
        let ahora = new Date()
        let dif = (date_expire_at.getTime() - ahora.getTime())/1000
        if(dif < min_expired) {
            min_expired = dif;
        }
    }

    min_expired = Math.round(min_expired)

   let commisionData = await getComissionData(publicToken); 
   const commisionData3 = {
        commissionWeb: 7, // Esta es la comision del total de la venta. A esta comision luego se aplicarán los porcentajes de comision de cada uno
        civitripCommision: 23, 
        ownerCommision: 23, 
        ownerMultisafeID: "90316177",  // nueva cuenta de Antonio, simula propietario
        civitripMultisafeID: "90274504", // cuenta de marketing
        ticandoMultisafeID: "90315979" // cuenta creada por Antonio 
    }

   // let cantidadComisionWeb = amount * commisionData.commissionWeb / 100;

   /* let ticandoCommision = 100 - (commisionData.civitripCommision + commisionData.ownerCommision)
    let cantidadCivitrip = (cantidadComisionWeb * commisionData.civitripCommision) / 100;
    let cantidadOwner = (cantidadComisionWeb * commisionData.ownerCommision) / 100;
    let cantidadTicando = (cantidadComisionWeb * ticandoCommision) / 100;*/

    let porcentajeCivitrip = commisionData.commissionWeb*commisionData.ownerCommision/100;
    let porcentajeOwner = commisionData.commissionWeb*commisionData.ownerCommision/100;
    let porcentajeTicando = commisionData.commissionWeb*(100-commisionData.ownerCommision+commisionData.ownerCommision)/100

 /*console.log('cantidadComisionWeb ',cantidadComisionWeb)
    console.log('cantidadCivitrip ', cantidadCivitrip)
    console.log('cantidadOwner ',cantidadOwner)
    console.log('cantidadTicando ',cantidadTicando) */

    return JSON.stringify({
        type: "redirect", // Puede ser direct o redirect, direct: el cliente elige el metodo de pago antes y despues redirecciona y redirect primero redirecciona y luego se elige pago
        order_id: order_id,
        currency: money,
        amount: amount, // El amount ya está multiplicado por 100
        description: description,
        affiliate: {
            split_payments: mountSplitPayments([
                                                    {id: 1, merchant_id: Number(commisionData.civitripMultisafeID), percentage: porcentajeCivitrip,description: "Comision civitrip"},
                                                    {id: 2, merchant_id: Number(commisionData.ticandoMultisafeID), percentage: porcentajeTicando,description: "Comision Ticando"},
                                                    {id: 3, merchant_id: Number(commisionData.ownerMultisafeID), percentage: porcentajeOwner,description: "Comision Owner"}
                                            ]) // REVISAR
        },
        payment_options: { 
            notification_url: url_post, // Se llama con cualquier notificacion. Este hace la llamada al endpoint del server para generar los tickets
            notification_method: 'POST',
            redirect_url: url_ok, // Este abre la pagina de pago correcto que llama a la pagina que abre los tickets en pdf
            cancel_url: url_ko, // Si se da a Cancelar, se redireccionará a la página del carrito
            close_window: true 
        },
        gateway: 'CREDITCARD', //CREDITCARD For redirect requests using the generic CREDITCARD gateway, the payment page automatically detects the card scheme.
        customer: {
            locale: 'es_ES',
            first_name: name,
            last_name: surnames,
            phone: phone,
            email: email
        },
        custom_info: {
            custom_1: additionalInfo, // Es additionalInfo
            custom_2: "", // Si additionalInfo es mayor que 500, custom2 tendrá contenido
            custom_3: "" // Si custom2 es mayor que 500, custom3 tendrá contenido
        },
        seconds_active: min_expired,
        second_chance: {
            send_email: true
        }
    });

}

const mountSplitPayments = (affiliates: Affiliate[]) => {
    const split_affiliates: Split_Affiliate[] = [];
    
    affiliates.forEach((affiliate) => {
        split_affiliates.push({
            merchant: affiliate.merchant_id,
            percentage: affiliate.percentage,
            description: affiliate.description
        });
    });

    return split_affiliates;
}

export const mountTrustMyTravelBody = async (total: number, description: string, nombre: string, apellidos: string, telefono: string, email: string, channel_id: number, money: string, publicToken, uuid) => {
    //channel_id = 14388;
    const date = Date.now()
    const dateFormat = Moment(date).format('YYYY-MM-DD')
    try{
        const auth = await getAuthenticationTMT(channel_id, total, date, money, publicToken);
        console.log('auth ',auth)
        const path = auth.tmt.sites ? auth.tmt.sites[0].path : auth.tmt.default_site.path

        if (!auth) {
            throw new Error('error recibiendo auth')
        }

        return {body: {
            // Datos requeridos para todas las transacciones
            booking_auth: auth.auth,
            booking_id: "0", // 0 para crear una nueva reserva
            channels: channel_id, // channel_id,
            payee_name: `${nombre} ${apellidos}`,
            payee_email: email,
            payee_country: "ES",
            currencies: money,//'EUR',  // Esta moneda deberá ser la de baseCurrency of channel
            total: total,
    
            // datos requeridos para nueva reserva
            country: "ES", // Este es el país que en el dashboard sale como country to travel
            firstname: nombre,
            surname: apellidos,
            email: email,
            date: dateFormat,
    
            // datos de direccion (del que realiza el pago???
            payee_address: " ",
            payee_city: " ",
            payee_postcode: " ",
    
            reference: uuid,
            description: `Ticando Order - ${uuid}`,
    
        },
        path: path
    
        };
    }catch(e){
        throw e;
    }
}


export const getAuthenticationTMT = async(channel_id: number, total: number, date, money, publicToken) => {
    let request
    try {
        // Control de errores hecho
        request = await fetch(
            `${baseURL}${prefix}/tmt/authentication?channel_id=${channel_id}&total=${total}&date=${date}&money=${money}&publicToken=${publicToken}`
        );
        if(request.statusCode >= 400){
            throw new Error;
        }
        const res = request.json();
        return res;
    } catch (e) {
        console.log('getAuthenticationTMT - error: ', e)
        throw e;
    }
    return null

}

export const getDominio = async(publicToken: string) => {
    let request
    try {
        // Control de errores hecho
        request = await fetch(
            `${baseURL}${prefix}/getDominio`,{
                headers: { "Content-Type": "application/json", authorization: `Bearer ${publicToken}` },
                credentials: "include",
                method: "GET",
            });
        const res = request.text()
        return res;
    } catch (e) {
        console.log('getDominio - error: ', e)
        throw e;
    }
}


export const paymentTMTHandler =   (body, url_post: string, url_ok: string, additional_info: string, history: any, publicToken: string, money: string, dominio: string, path_tmt: string, t, handlerOk, handlerError, cart) => {
    // prueba 
    //probar(additional_info);
    let path = path_tmt.substr(1,path_tmt.length);
   path = path.substr(0,path.length-1)

    let message = "";

    let modal; 

    let i = 0;

    if(process.env.REACT_APP_ENVIRONMENT === 'dev'){
         modal = (window as any).tmtPaymentModalSdk({
            path: path, 
            origin: `${process.env.REACT_APP_SUBDOMINIO_DEV}, ${dominio}`,
            environment: "test", // quitar cuando estemos en PROD
            debug: true, // quitar cuando estemos en PROD
            lang: "esES",
            paymentCurrency: money,
            data: body,
        }) as ITMTModal;

    }else if(process.env.REACT_APP_ENVIRONMENT === 'prod'){
         modal = (window as any).tmtPaymentModalSdk({
            path: path, 
            origin: `${process.env.REACT_APP_SUBDOMINIO_PROD}, ${dominio}`,
            environment: "live", // en PROD es live, o si se quita por defecto es live
            lang: "esES",
            paymentCurrency: money,
            data: body,
        }) as ITMTModal;
    }

    let expired = false;
    const tickets = [...cart.cartTickets, ...cart.cartCollaboratorTickets, ...cart.cartPacketTickets, ...cart.cartCollaboratorPacketTickets]
    while(i<tickets.length && !expired){
        if(tickets[i].expire_at !== null){
            if(new Date(tickets[i].expire_at) < new Date()) { // Ha expirado
                expired = true
            }
        }else{
            const treintaMins = new Date(tickets[i].created_at);
            treintaMins.setSeconds(treintaMins.getSeconds()+30*60)
            if(treintaMins < new Date()){
              expired = true
            }
        }
        i++;
    }

    if(expired){
        console.log('expired ')
      modal.close()
    }

    modal.on("token_error", (data: any) => {
        // Token error, something has ocurred with auth token
        console.log('token_error - data: ', data)
        //handlerError(data)
        modal.closeModal();
        message = handlerErrors(data.message, t)
        //message = `El problema generado desde la pasarela de pago es el siguiente: ${data.message}/`;
        //history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`token_error - ${message}`));
        modalError(message);
    });

    modal.on("booking_error", (data: any) => {
        // Booking error, redirect to error page
        console.log('booking_error: ', data)
        modal.closeModal();
        //message = `El problema generado desde la pasarela de pago es el siguiente: ${data.message}`;
        message = handlerErrors(data.message, t)
        Sentry.captureException(new Error(`booking_error - ${message}`));
        modalError(message);
       // history.push(`/${publicToken}/error/${message}`); // Send to error page
    });

    modal.on("transaction_logged", (data: any) => {
        // Transaction logged, the transaction has been paid and now we want to redirect to the ticando
        // ticket and fetch to POST payment process
        const payload = JSON.stringify({
            data: data,
            additional_info: additional_info,
            status: data.status,
        });

        modal.closeModal();

        paymentSuccessfullTMT(payload, url_post).then((res:any) => {
            try{
                res = JSON.parse(res)
            }catch(e){
                Sentry.captureException(new Error(`paymentSuccessfullTMT - error parse`));
            }

            if (res.result !== "OK") {
                // console.log('paymentSuccessfullTMT - res: ', res);
                // Reenviamos la petición
                paymentSuccessfullTMT(payload, url_post).then((res:any) => {
                    try{
                        res = JSON.parse(res)
                    }catch(e){
                        Sentry.captureException(new Error(`paymentSuccessfullTMT - error parse`));
                    }
                    if (res.result !== "OK") {
                        // Pensar que hacer
                        //handlerError(res)
                        message = handlerErrors(data.content, t);
                        modalError(message);
                    } else {
                        //sendEmail(additional_info);
                        // window.location.href = url_ok;

                        handlerOk(res)
                    }
                }).catch(e => {throw e});
            } else {
                //sendEmail(additional_info);
                // window.location.href = url_ok;
                handlerOk(res)                
            }
        });
    });

    modal.on("transaction_failed", (data: any) => {
        // Transaction failed, somethig happens in the payment checkout, redirect to error page
        console.log('transaction_failed: ', data)
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // message = `El problema generado es el siguiente: ${data.content}`;
        //history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`transaction_failed - ${message}`));
        modalError(message);
    });

    modal.on("transaction_timeout", (data: any) => {
        // Transaction timeout, somethig happens in the payment checkout, redirect to error page
        console.log('transaction_timeout: ', data)
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // message = `El tiempo de espera con el banco se ha agotado.`;
        // history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`transaction_timeout - ${message}`));
        modalError(message);
    });

    modal.on("transaction_error", (data: any) => {
        // Transaction error, during the transaction has ocurred some error, redirect to error page
        console.log("transaction error, data: ", data);
        message = handlerErrors(data.message, t)
        modal.closeModal();
       // message = `Hemos obtenido un error inesperado desde la pasarela de pago.`;
       // history.push(`/${publicToken}/error/${message}`); // Send to error page
       Sentry.captureException(new Error(`transaction_error - ${message}`));
        modalError(message);
    });

    modal.on("close_window_attempted", (data: any) => {
        // Transaction error, during the transaction has ocurred some error, redirect to error page
        console.log("close_window_attempted error, data: ", data);
        message = handlerErrors(data.content, t)
        modal.closeModal();
        // history.push(`/${publicToken}/error/${message}`); // Send to error page
        Sentry.captureException(new Error(`close_window_attempted - ${message}`));
        modalError(message);
    });
};

export const paymentSuccessfullTMT = async(payload: string, url_post: string) => {
    try{
        // Control de errores hecho
        const request = await fetch(url_post ,{
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            body: payload,
            method: "POST",
        });
        return request.text();
    }catch(e){
        console.log('paymentSuccessfullTMT - catch ',e)
        throw e;
    }

}

export const modalError = async(message: string, onIsConfirmed?) => {
    Swal.fire({
        icon: 'error',
        title: 'Error',
        text: message,
        footer: 'Por favor, vuelva a intentarlo de nuevo. \n Gracias y disculpe las molestias'
      }).then((result) =>{
          if(result.isConfirmed && onIsConfirmed){
            onIsConfirmed();
          }
      })
}

export const modalInfo = async(message: string,t: any, onIsConfirmed?) => {
    Swal.fire({
        icon: 'info',
        title: t("info"),
        text: message,
      })
}


export const modalWarning = async(message: string, onIsConfirmed) => {
    Swal.fire({
        icon: 'warning',
        title: 'Atención',
        text: message
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            onIsConfirmed();
        }
    })
}

/*export const crearOrdenPago = async () =>{
    const request = await fetch(`${baseURL}${prefix}/crearOrdenPagoGreenpay`, {
        headers: { 'Content-Type': "application/json", authorization: `Bearer ${publicToken}` },
        credentials: 'include',
        method: 'GET'
    })
}*/

function greenPayResponse(id, res){
    //Add your logic
    if(res.status === 200){
        //Response is OK, someting payed
        console.log("payed", JSON.stringify(res))
    }
    else{
        //An ERROR ocurred
        console.log("error", JSON.stringify(res))
    }
}

// Pruebas

export const probar = async(payload: string) => {
    const request = await fetch(`${baseURL}${prefix}/marko` ,{
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: payload,
        method: "POST",
    });
    return request.text();
}

export const sendEmail = async(payload: string) => {

    const request = await fetch(`${prefix}/payment/cartresult` ,{
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        body: payload,
        method: "POST",
    });
    return request.text();
}


export const resultGreenpayTicando = async(dataEncrypted: string, isValid, url_post: string) => {
    try{
        const request = await fetch(url_post, {
            headers: { 'Content-Type': "application/json"},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({dataEncrypted: dataEncrypted, isValid: isValid})
        })
console.log('request ',request)
        if(request.status >= 400){
            return new Error(request.status+'')
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const isValidGreenpayOrder = async(dataEncrypted: string, publicToken) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/isValidGreenpayOrder`, {
            headers: { 'Content-Type': "application/json", 'Authorization': `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({dataEncrypted: dataEncrypted})
        })

        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const getOrderMultiSafePay = async(transactionid: string, publicToken, environment) => {
    try{
        const request = await fetch(`${baseURL}${prefix}/getOrderMultiSafePay`, {
            headers: { 'Content-Type': "application/json", "Authorization": `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({transactionid: transactionid, environment: environment})
        })
        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
}

export const resultStripe = async(publicToken, url_post, payment_intent_id) =>{
    try{
        const request = await fetch(`${baseURL}${prefix}/retrieve-payment-intent`, {
            headers: { 'Content-Type': "application/json", "Authorization": `Bearer ${publicToken}`},
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({url_post: url_post, payment_intent_id: payment_intent_id})
        })
        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res; 
    }catch(e){
        throw e;
    }
    
}