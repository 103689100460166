import { PaymentGateway } from "./paymentGateway";
import { mountTrustMyTravelBody, paymentTMTHandler } from "./Request";
import * as Sentry from "@sentry/react";
import { getChannels } from "Request/Request";

export  class TrustMyTravel extends PaymentGateway{

    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    description: string;
    url_post: string;
    url_ok: string;
    url_ko: string;
    name: string;
    surname: string;
    telephone: string;
    email: string;
    channel_id: any;
    additional_info: any;
    money: string;
    dominio: string;
    history: any;
    t: any;
    path: any;
    handlePaymentOk: any;
    handlePaymentError: any;
    uuid: string;
    cart: any;

    constructor(publicToken: string, totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, channel_id: number, additional_info: string, money: string, dominio: string, history:any, t:any, handlePaymentOk:any, handlePaymentError:any, uuid: any, cart:any){
        super();
        this.publicToken = publicToken;
        this.namePasarela = 'TRUSTMYTRAVEL';
        this.totalAmount = totalAmount;
        this.description = description;
        this.name = name;
        this.surname = surname;
        this.telephone = telephone;
        this.email = email;
        this.channel_id = channel_id;
        this.additional_info = additional_info;
        this.money = money; 
        this.dominio = dominio;
        this.history = history;
        this.t = t;
        this.handlePaymentOk = handlePaymentOk;
        this.handlePaymentError = handlePaymentError;
        this.uuid = uuid;
        this.cart = cart;
    }

    set_url_post(url_post): void {
        this.url_post = url_post;
    }

    set_url_ok(url_ok): void {
        this.url_ok = url_ok;
    }

    set_url_ko(url_ko): void {
        this.url_ko = url_ko;
    }

    async pay(): Promise<void> {
        try{

            if(this.channel_id === 0) {  // Quiere decir que antes habia otra pasarela y se ha cambiado justo antes de clicar en reservar. Hacer peticion para obtener el channel_id
                let channels = await getChannels(this.publicToken)
                if(channels.msg){
                    this.channel_id = 0 // Valor arbitrario si la pasarela no es TMT
                } else {
                    let aux;
                    aux = channels.filter(c => this.money === c.currencies);
                    this.channel_id = aux[0]  
                }
            }
            const res = await mountTrustMyTravelBody(this.totalAmount * 100, this.description, this.name, this.surname, this.telephone, this.email, this.channel_id, this.money, this.publicToken, this.uuid)
            let body = res.body;
            let path = res.path;
            paymentTMTHandler(body, this.url_post, this.url_ok, this.additional_info, this.history, this.publicToken, this.money, this.dominio, path, this.t, this.handlePaymentOk, this.handlePaymentError, this.cart)
        }catch(e) {
            Sentry.captureException(new Error("TrustMyTravel - mountTrustMyTravelBody-paymentTMTHandler"));
            this.history.push(`/${this.publicToken}/error/Ha ocurrido un error - mountTrustMyTravelBody-paymentTMTHandler`)
        }
    }
}