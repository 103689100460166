import {getAllData} from '../../Request/Request'

export const getOperationLine = async (operationLine_id: number, publicToken: string) => {
    // return await baseRequest<OperationLine>(`operationLine?id_op=${id_operationLine}&idTicando=${idTicando}`);
    try {
    const data = await getAllData(publicToken)
    if(data.statusCode === 401){     
        return {statusCode: data.statusCode}
    }
        return data.operationLines.find(operationLine => operationLine.id === operationLine_id)
    } catch (e) {
        console.log('error: ', e)
        throw e;
    }
    // return hardcode.find(operationLine => operationLine.id === operationLine_id)
}
