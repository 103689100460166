import { useStripe } from "@stripe/react-stripe-js";
import { IPasarelaPago } from "models/PasarelaPago";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import * as Sentry from "@sentry/react";

interface Props {
    stripePromise?: any
    clientSecret?:any
    urlBasePost: any
    publicToken?: string
  }

export default function StripeProcessing(props: Props) {
    let {clientSecret, urlBasePost, publicToken} = props
    const stripe = useStripe();
    const history = useHistory()

    const [paymentIntent, setPaymentIntent] = useState<any>()
    
    const STRIPE: IPasarelaPago = {
        id: 5, name: 'STRIPE'
    }

    useEffect(()=>{

        if (!stripe){
            return
        }

        if(!paymentIntent) {
            stripe.retrievePaymentIntent(clientSecret).then(res => {
                setPaymentIntent(res.paymentIntent)
            }) 

        } else {

            switch (paymentIntent.status) { //payment_intent=pi_3L2tsWAIUUsdAsyQ35eOu2I3
                // Si se ha realizado el pago correctamente, llamo al endpoint de ticando payment/cartresult donde regenero los tickets y envio emails
                case "succeeded":
                    //let payment_intent_id = new URLSearchParams(window.location.search).get("payment_intent")
                    let url_post = `${urlBasePost}&pasarela=${STRIPE.name}`
                    
                   fetch(url_post, {
                        headers: { 'Content-Type': "application/json"},
                        credentials: 'include',
                        method: 'POST',
                        body: JSON.stringify(paymentIntent)
                    })
                    history.push(`/${publicToken}/payment/success`)  
                  break;
                case "processing":
                 // setMessage("Your payment is processing.");
                  break;
                case "requires_payment_method":
                    Sentry.captureException(new Error(`Stripe processing - default`));
                    history.push(`/${publicToken}/cart/?error=${true}`)
                  break;
                default:
                    Sentry.captureException(new Error(`Stripe processing - default`));
                    history.push(`/${publicToken}/cart/?error=${true}`)
                  //setMessage("Something went wrong.");
                  break;
              }
        }
    },[stripe, paymentIntent])
    return null
}