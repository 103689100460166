import React from "react";

export const NoFound = () => { 
    return (
        <>
            <div className="col-7">
                <div className='text-input__loading'>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                </div>
            </div>
            <div className="col-5">
                <div className='text-input__loading'>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                    <div className='text-input__loading--line'/>
                </div>
            </div>

        </>
    )
}
