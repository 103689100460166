import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterView } from './FooterView';
import { useTranslation } from "react-i18next";

interface Props {
    public?: string
    redirect_status?: any //http://localhost:3002/TEGiEYtLDltCpTWCRhsJdMLxLdaBABlTFrDJQvUUhOWYhsZzaSJEdUCoxbpxcbSiTUrkRM/payment/success?payment_intent=pi_3L2sW5AIUUsdAsyQ1SREZARx&payment_intent_client_secret=pi_3L2sW5AIUUsdAsyQ1SREZARx_secret_iwZPrrybjnfgbBAnafsExRDsR&redirect_status=succeeded
}

type CorrectoProps = RouteComponentProps<Props>;

export const PagoCorrecto: React.FC<CorrectoProps> = (props: CorrectoProps) => {
    const publicToken = props.match.params.public;
    const redirect_status = new URLSearchParams(window.location.search).get(
        "redirect_status"
      );
    const history = useHistory()

    const [t,i18n] = useTranslation("global");
    const [data, setData] = useState<any>()

    let idioma : any = null;
    idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma])

    useEffect(()=> {
       // const tokenTicket = localStorage.getItem('tokenTicket')
       if(!data){
        setData({
            tokenTicket: localStorage.getItem('tokenTicket'),
            clientName : localStorage.getItem('clientName')
        })
    }
    if(data && data.tokenTicket !== null && data.clientName !== null){
       /* if(redirect_status === 'succeeded'){
            // Llamo a endpoint de crea ticket
            const url_post = `${process.env.REACT_APP_baseURLTicando}/paymentcart/result?public=${publicToken}&cartToken=${data.tokenTicket}$pasarela=STRIPE`
            fetch(`${url_post}`, {
                headers: { 'Content-Type': "application/json"},
                credentials: 'include',
                method: 'POST',
            })
        }       */
            
        let url = `https://ticket.ticando.net/?client=${data.clientName}&numero=${data.tokenTicket}`
        setTimeout(()=>{ window.open(url,"_blank");
                         localStorage.removeItem('clientName')
                         localStorage.removeItem('tokenTicket')
                        },2000)
        }
    },[data])

    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">
                        <li className="test-flow-node-breadcrumb" aria-label="Home"
                            title="Home">
                            <a onClick={() => {history.push(`/${publicToken}/main/`)}}>
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-home"
                                    className="icon-home" width="13" height="13" viewBox="0 0 13 13"
                                >
                                    <path
                                        d="M5.63.28l-5,3.54A1.61,1.61,0,0,0,0,5.15v6.52A1.32,1.32,0,0,0,1.34,13H4.46V9.66a2.06,2.06,0,0,1,4.12,0h0V13h3.08A1.31,1.31,0,0,0,13,11.72V5.15a1.66,1.66,0,0,0-.68-1.33L7.41.28A1.57,1.57,0,0,0,5.63.28Z"/>
                                </svg>
                            </a>
                        </li>                       
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button"
                                    aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                     id="icon-svg-lock" className="icon-svg-lock" width="11" height="14"
                                     viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path
                                        d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                               {t("securePayment")}
                            </button>

                        </li>
                    </ul> 
                </div>

            </header>
            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                    <div className="row">
                        <div className="tour-sidebar">
                            <div className="tour-sidebar__featured pagoCorrecto">
                                <div className="svg-box">
                                    <svg className="circular green-stroke">
                                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth={5} strokeMiterlimit={10} />
                                    </svg>
                                    <svg className="checkmark green-stroke">
                                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                            <path className="checkmark__check" fill="none"
                                                  d="M616.306,283.025L634.087,300.805L673.361,261.53"/>
                                        </g> 
                                    </svg>
                                </div>
            <h1>{t("correctPayment")}</h1>
            <div>
            {t("correctPaymentmsg")}
            </div>
        </div>
                    </div>
                    </div>
                </div>
            </section>

            <FooterView/>
        </div>
    );
}
