import OperationLine from 'models/OperationLine'
import Stretch from '../../models/Stretch'

export const addStretchToCart = async (ticket, authorization) => {
    // voy a hacer la conexión directamente a creaticket.
    const baseURLTicando = process.env.REACT_APP_baseURLTicando
    try{
        // Control de errores hecho
        const request = await fetch(
            `${baseURLTicando}/tokenticket/addcart3`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-provider": "creaticket",
                    "Authorization": `Bearer ${authorization}`
                },
                credentials: 'include',
                body: JSON.stringify(ticket)
            }
        )
        if(request.status >= 400){
            throw new Error()
        }
        const res = request.json()
        return res
    }catch(e){
        throw e;
    }

}

export const getTrayectoByID = async (operationLine: OperationLine, stretch_id: number) => {
    // return await baseRequest<Stretches>(`trayecto?idTicando=${idTicando}&id_operationLine=${id_operationLine}&id_trayecto=${id_trayecto}`);
   // const operationLine = await getOperationLine(operationLine_id, publicToken)
    const stretch: Stretch = operationLine.stretches.find(stretch => stretch.id === stretch_id)
    return new Promise<Stretch>((resolve, reject) => {
        return resolve(stretch)
    })
}
