import React from "react";
import { Dialog, DialogActions } from "@material-ui/core";
import { DateSelectView } from './DateSelectView'
import { TypeStretch } from '../models/TypeStretch'
import { ViewApi } from "@fullcalendar/react";
import  {filterHours} from './CalendarAvailable'

interface Props {
    selected: ISelected
    handleDayRender(info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }, disabledDays): void
    handleSetOpenDatePopup(value: boolean): void
    handleDatesSelelected(dateSelected: Date, index: number | string, isStretchCollaborator: boolean): void
    handleDialogActions(): void
    t: any
}

export const DialogDateSelectView = (props: Props) => {
    const { selected, handleDayRender, handleSetOpenDatePopup, handleDatesSelelected, handleDialogActions, t } = props
    return (
        <>
            <Dialog open={!!selected.stretchSelected} fullWidth>
                <p>{selected.stretchSelected && selected.stretchSelected.name}</p>
                <DateSelectView
                    index={selected.indexSelected}
                    disabledDays={selected.stretchSelected?.disabledDays}
                    horasIda={selected.stretchSelected?.horasIda}
                    isStretchCollaborator={selected.isStretchCollaborator}
                    dayRender={(arg) => handleDayRender(arg, (selected.stretchSelected && selected.stretchSelected.disabledDays) || [])}
                    onDateClick={(date, index, isStretchCollaborator) => {

                        // busco si está en disabledDays
                        const dateSelected = new Date(date)
                        const result = selected.stretchSelected.disabledDays?.find(day => {
                            if (day.day === dateSelected.getDate() &&
                                day.month === dateSelected.getMonth() &&
                                day.year === dateSelected.getFullYear()
                            ) {
                                return true
                            }
                            return false
                        })
                        // compruebo si todas las horas estan deshabilitadas
                        
                        if (result && allHoursDisabled(selected.stretchSelected.horasIda, result.hours)) {
                            handleSetOpenDatePopup(true)
                            return
                        }
                        
                        // Compruebo si el día es anterior a hoy
                        let todaysDate = new Date();
                        todaysDate.setHours(0, 0, 0, 0);
                
                        const beforeToday = dateSelected < todaysDate;
                        if (beforeToday) {
                            handleSetOpenDatePopup(true)
                            return
                        }

                        handleDatesSelelected(dateSelected, index, isStretchCollaborator)
                    }}
                />
                <DialogActions>
                    <button type="button" className="btn btn-secondary"
                        onClick={handleDialogActions}
                    >
                       {t("close")}
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export const allHoursDisabled = (horasIda, disableDayHours) => {
    const hoursFiltered = filterHours(horasIda, disableDayHours)
    return hoursFiltered.length === horasIda.length
}

export interface ISelected {
    stretchSelected: TypeStretch
    indexSelected: number | string
    isStretchCollaborator: boolean
}
