import { BasePacket } from "models/Packet";
import Stretch from "models/Stretch"
import { useTranslation } from "react-i18next";
import { Markup } from 'interweave';

interface Props {
    trayecto: Stretch | BasePacket
    orden?: number
}

export const InfoWeb = ({trayecto, orden}: Props) => {

    const [t,i18n] = useTranslation("global");
    let idioma = localStorage.getItem('idioma');
    let idiomaDefecto = trayecto.dataweb.defaultLanguage;
    if (!idioma || idioma === null){
        idioma = idiomaDefecto;
    }

    const info = trayecto.dataweb ? trayecto.dataweb.array : null;
    let Vacio = false;
    if(info){
        Vacio = info.descripcion[idioma] === '' &&
                      info.recomendaciones[idioma] === '' &&
                      info.localizacion[idioma] === '' &&
                      info.serviciosCercanos[idioma] === '' &&
                      info.normas[idioma] === '' &&
                      info.otros[idioma] === '' 
    }

    let infoArray = []
    let hayCustom = false;
    if(!Vacio){
        infoArray = [
            {title: `custom`, value: info && info.custom ? info.custom[idioma] : ''},
            {title: `${t("detailedDescription")}`, value: info ? (info.descripcion[idioma]) : ''},
            {title: `${t("recommendations")}`, value: info ? info.recomendaciones[idioma] : ''},
            {title: `${t("location")}`, value: info ? info.localizacion[idioma] : ''},
            {title: `${t("nearbyServices")}`, value: info ? info.serviciosCercanos[idioma] : ''},        
            {title: `${t("requirements")}`, value: info ? info.normas[idioma] : ''},
            {title: `${t("others")}`, value: info ? info.otros[idioma] : ''},
            {title: `terms`, value: info && info.terminosCondiciones ? info.terminosCondiciones[idioma] : ''},
        ]
    }else{
        infoArray = [
            {title: `custom`, value: info && info.custom ? info.custom[idioma] : ''},
            {title: `${t("detailedDescription")}`, value: info ? (info.descripcion[idioma]) : ''},
            {title: `${t("recommendations")}`, value: info ? info.recomendaciones[idioma] : ''},
            {title: `${t("location")}`, value: info ? info.localizacion[idioma] : ''},
            {title: `${t("nearbyServices")}`, value: info ? info.serviciosCercanos[idioma] : ''},        
            {title: `${t("requirements")}`, value: info ? info.normas[idioma] : ''},
            {title: `${t("others")}`, value: info ? info.otros[idioma] : ''},
            {title: `terms`, value: info && info.terminosCondiciones ? info.terminosCondiciones[idioma] : ''},
        ]      
    }
 

    let className = `tour-sidebar_description_${orden}`
    return (
        <div className={className}>
        {
            
            infoArray && infoArray.map((i)=>{ 
               

                if(i.title === 'custom' && i.value === ''){
                    hayCustom = false;
                }
                if(i.title === 'custom' && i.value !== '' && i.value){
                    hayCustom = true;
                    return( 
                            <Markup content={i.value} />
                            )
                }else if(!hayCustom){
                    return(                   
                        <>
                        {
                            i.value && i.value !== '' &&
                            <>
                               <Markup content={i.value} />
                                <br></br>
                            </>
                        }
                        </>
                    )
                } 

                if(i.title === 'terms' && i.value !== '' && i.value){
                    return( 
                        <span>
                            <Markup content={i.value} />
                        </span>
                    )                      
                } 
            })
        }
    </div>    
    )
}