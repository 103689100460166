import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FooterView } from './FooterView';

export const DefaultPage: React.FC = () => {

    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma === null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]); 

    return(
        <div className="page-wrapper animated fadeIn">
            <header className="book-embed-nav with-padding respond-print-hide">
                <div className="ben-right">
                    <ul className="ben-left">
                        <li className="ben-flyout-wrap">
                            <button className="ben-item ben-secure-button fh-green" type="button"
                                    aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false"
                                     id="icon-svg-lock" className="icon-svg-lock" width="11" height="14"
                                     viewBox="0 0 11.02 14" ng-svg="icon-svg-lock">
                                    <path d="M7.66,3.88a2.15,2.15,0,0,0-4.3,0v2h4.3Z" style={{fill: 'none'}}></path>
                                    <path
                                        d="M9.39,5.85v-2a3.88,3.88,0,0,0-7.76,0v2A1.72,1.72,0,0,0,0,7.56v4.73A1.72,1.72,0,0,0,1.71,14h7.6A1.72,1.72,0,0,0,11,12.29V7.56A1.71,1.71,0,0,0,9.39,5.85Zm-6-2a2.15,2.15,0,0,1,4.3,0v2H3.36Z"></path>
                                </svg>
                                {t("securePayment")}
                            </button>

                        </li>
                    </ul> 


                </div>

            </header>
            <br></br>
            <br></br>
            <br></br>
            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                        <div className="tour-sidebar">
                            <div className="tour-sidebar__featured pagoIncorrecto">

                                <div className="svg-box">
                                    <svg className="circular red-stroke">
                                        <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5"
                                                strokeMiterlimit="10"/>
                                    </svg>
                                    <svg className="cross red-stroke">
                                        <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                            <path className="first-line" d="M634.087,300.805L673.361,261.53"
                                                  fill="none"/>
                                        </g>
                                        <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                            <path className="second-line" d="M634.087,300.805L673.361,261.53"/>
                                        </g>
                                    </svg>
                                </div>
                                <h1>404: {t("pageNotFound")}</h1>
                                <div>
                                   {t("pageNotFoundmsg")}
                                </div>
                            </div>
                        </div>
                </div>
            </section>

            <FooterView/>
        </div>
    );
}