import React from 'react';
import { useTimer } from 'react-timer-hook';

interface Props {
    expiryTimestamp: Date,
    expiredHandler: ()=>void
    t:any 
}
export function MyTimer({ expiryTimestamp, expiredHandler, t }: Props) {
  let {
    seconds,
    minutes,
  } = useTimer({ expiryTimestamp, onExpire: () => expiredHandler()});

  let mins = ("0" + minutes).slice(-2)
  let secs = ("0" + seconds).slice(-2)



  return (
    <div style={{textAlign: 'center'}}>
      {t("remainingTime")}
        {
            minutes < 3 && ((minutes >= 0 && seconds > 0) || (minutes > 0 && seconds === 0)) &&
            <div style={{borderRadius: '10px', backgroundColor:'orange', fontSize: '14px', color: 'white', opacity: '75%',}} className='badge badge-warning'>
            <span>{mins}</span>:<span>{secs}</span>
          </div>
        }
        {
            minutes === 0 && seconds === 0 &&
            <div style={{borderRadius: '10px', backgroundColor:'red',fontSize: '14px',  color: 'white', opacity: '75%', border: '3px solid red'}} className='badge badge-danger'>
            <span>{mins}</span>:<span>{secs}</span>
          </div>
        }
        {
            minutes >= 3 &&
            <div style={{borderRadius: '10px', fontSize: '14px',backgroundColor:'#007025', opacity: '75%',color: 'white'}} className='badge badge-success'>
            <span>{mins}</span>:<span>{secs}</span>
          </div>
        }

    </div>
  );
}