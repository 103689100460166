import React, { useState, useEffect, useRef } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import SeatPicker from "react-seat-picker"
import Moment from 'moment'
// import '../styles/style.css'
import { useHistory } from 'react-router'
import { RouteComponentProps } from 'react-router'
import {
    AddCallBackType,
    RemoveCallBackType,
    addVisitanteGlobal,
    generateTokenticket,
    horasIdaAvailable,
    mountPayloadStretch,
    quitarVisitanteGlobal, visOK
} from '../Util'
import {TourListLoading} from "../../components/TourListLoading"
import {CtaView} from "../../components/CtaView"
import {DialogNoDate} from "../../components/DialogNoDate"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import {Carousel} from "react-responsive-carousel"
import CalendarAvailable from "../../components/CalendarAvailable"
// import './innerCSS.css'
import {HeaderView} from '../../components/HeaderView'
import Extra from '../../models/Extra'
import {IHora} from '../../models/IHora'
import {TypeTicket} from '../../models/TypetTicket'
import {addStretchToCart, getTrayectoByID} from './Request'
import {ISeat} from '../../models/ISeat'
import {Vis} from '../../models/Vis'
import Stretch from '../../models/Stretch'
import OperationLine from '../../models/OperationLine'
import {getOperationLine} from '../OperationLine/Request'
import {ViewApi} from '@fullcalendar/react'
import { InfoWeb } from 'components/InfoWeb'
import { PricesView } from 'components/PricesView'
import { FooterView } from 'components/FooterView'
import { getChannels } from 'Request/Request'
import { useTranslation } from 'react-i18next'
import { modalError, modalInfo } from 'Modules/Payment/Request'
import Spinner from 'reactstrap/es/Spinner'
import * as Sentry from "@sentry/react";
import { ExtrasView } from 'components/ExtrasView'

interface TrayectoParams {
    stretch_id: string
    operatioLine_id: string
    public: string
}

type TrayectoProps = RouteComponentProps<TrayectoParams>

export const StretchPage: React.FC<TrayectoProps> = (props: TrayectoProps) => {

    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }
    
    const publicToken = props.match.params.public
    const stretch_id = Number(props.match.params.stretch_id)
    const operationLine_id = Number(props.match.params.operatioLine_id)
    const history = useHistory()
    const init: Vis = { names: [], indices: [], vis: [], price: [], base: 0.00, total: 0.00, extras: [], disabledButtons: false }
    const CHECKED_CLASSNAME = "btn btn-primary"
    const UNCHECKED_CLASSNAME = "btn btn-outline-primary"

    const [trayecto, setTrayecto] = useState<Stretch>()
    const [operationLine, setOperationLine] = useState<OperationLine>()
    const [channels, setChannels] = useState<any>();

    const [openDatePopup, setOpenDatePopup] = useState<boolean>(false)
    const [openReservarPopup, setOpenReservarPopup] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [visitas, setVisitas] = useState<Vis>(init)
    const [, setDD] = useState<Object[]>([])
    const [dateClicked, setDateClicked] = useState<string>("")
    const [selectedHour, setSelectedHour] = useState<IHora>()
    const [totalSeats, setTotalSeats] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [disabledCanal, setDisabledCanal] = useState<boolean>(false)
    const [, setTrayectoIdaVuelta] = useState<boolean>(false)
    const [soloIdaClassName, setSoloIdaClassName] = useState<string>(CHECKED_CLASSNAME)
    const [idaVueltaClassName, setIdaVueltaClassName] = useState<string>(UNCHECKED_CLASSNAME)
    const [horasDisponibles, setHorasDisponibles] = useState([])
    const [adding, setAdding] = useState<boolean>(undefined);
    const [limiteCapacidad,setLimiteCapacidad] = useState<boolean>(false)
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();

    let description = useRef('')
    let amount = 0
    let canalSi = false;

    useEffect(() => {
        if (!operationLine) {
            getOperationLine(operationLine_id, publicToken).then(operationLine => {
                if(operationLine.statusCode === 401){
                    Sentry.captureException(new Error("Stretch - Cliente no encontrado"));
                    history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                    return null
                }
                setOperationLine(operationLine)
            }).catch(e => {
                Sentry.captureException(new Error("Stretch - getOperationLine"));
                history.push(`${publicToken}/error/Ha ocurrido un error - getOperationLine`)
            })
        }
    },[])

    useEffect(() =>{
        if(!channels){
            getChannels(publicToken).then((res)=>{
                if(res.msg === 'No TMT'){
                    res = 'notmt';
                }else if(res.msg === 'GREENPAY'){
                    setMonedasGreenpay(res.monedasDisponibles)
                    res = 'greenpay'
                }
                setChannels(res);
            })          
        }
    },[channels])
    
    useEffect(() => {
        i18n.changeLanguage(idioma);  
        if (!trayecto && operationLine) {
            getTrayectoByID(operationLine, stretch_id).then((res) => {
                    setTrayecto(res)
                })
                .catch(error => {
                    console.log('error: ', error)
                })
        } else if (trayecto) {
            description.current = trayecto.name
            let auxDD: Object[] = []
            if (trayecto.disabledDays) {
                trayecto.disabledDays.forEach((disabledDay) => {
                    let str: any = '{ "title": "No Disponible", '
                    const date = new Date(disabledDay.year, disabledDay.month, disabledDay.day)
                    str += '"date": "' + Moment(date).format("YYYY-MM-DD") + '", '
                    str += '"constraint": "noDisponible", '
                    str += '"backgroundColor": "#ff9f89", '
                    str += '"display": "background"'
                    str += '}'
                    let strjs = JSON.parse(str)
                    strjs = {
                        title: 'No disponible',
                        date: Moment(date).format("YYYY-MM-DD"),
                        constraint: 'noDisponible',
                        backgroundColor: '#ff9f89',
                        display: 'background'
                    }
                    auxDD.push(strjs)
                })
                setDD(auxDD)
            }

            if (trayecto.prices) {
                setDisabled(trayecto.prices.length === 0)
                let auxVis: number[] = []
                let auxPrice: number[] = []
                let auxNames: string[] = []
                const indices: number[] = []
                trayecto.prices.forEach((precio) => {
                    auxVis.push(0)
                    auxPrice.push(0.00)
                    auxNames.push(precio.passengerCategory.name)
                })
                
                    setVisitas({ names: auxNames, indices, vis: auxVis, price: auxPrice, base: 0.00, total: 0.00, extras: [], disabledButtons: false })
                setTotalSeats(0)
            } else {
                setDisabled(true)
            }
        } 

        if(trayecto){
            if(channels && channels !=='notmt' && channels!== 'greenpay'){
                channels.map(c=>{                  
                    if(c.currencies === trayecto.primaryMoney.alphabeticCode){
                        canalSi = true;
                    }
                })
                setDisabledCanal(!canalSi)
            } else if(channels && channels === 'greenpay' && trayecto){
                let moneda = trayecto.primaryMoney.alphabeticCode
                if(monedasGreenpay.includes(moneda)){
                    canalSi = true;
                }
                setDisabledCanal(!canalSi)
            }
        }

    }, [trayecto, operationLine, stretch_id, channels])

    useEffect(() => {
        if(trayecto){
            const horas = trayecto.horasIda;
            if (horas && horas.length !== 0 && !selectedHour) {
                setSelectedHour(horas[0])
                setHorasDisponibles(horas)
            } 
        }
    }, [horasDisponibles])


    const clickDateHandler = (arg: {
        date: Date
        dateStr: string
        allDay: boolean
        dayEl: HTMLElement
        jsEvent: MouseEvent
        view: ViewApi
    }) => {
        const date = arg.date
        let pertenece: boolean = false 

        if (trayecto) {
            if (trayecto.disabledDays) {

                // miro si el día clicado está en deshabilitados
                const position = trayecto.disabledDays.findIndex(disabledDay =>
                    disabledDay.day === date.getDate() && disabledDay.month === date.getMonth() && disabledDay.year === date.getFullYear()
                )

                if (position === -1) { // no está en disabledDays, el día está habilitado
                    if (trayecto.tramoHora && trayecto.horasIda) {
                        setHorasDisponibles(trayecto.horasIda)
                    }
                } else { // el día está en disabledDays. puede ocurrir varias condiciones
                    // busco el día deshabilitado
                    const dayDisabled = trayecto.disabledDays[position]

                    // tramoHora: si hours.length === 0 all dia deshabilitado, si hours.length >0 tiene horas deshabilitadas
                    if (!trayecto.tramoHora) {// no es tramoHora, el día está deshabilitado
                        pertenece = true
                    } else {
                        if (dayDisabled.hours.length === 0) { // todas las horas deshabilitadas
                            setHorasDisponibles([])
                            pertenece = true
                        } else {
                            const horasIda = trayecto.horasIda || []
                            const horasIdaReduced = horasIdaAvailable(horasIda, dayDisabled)
                            setHorasDisponibles(horasIdaReduced)
                            if (horasIdaReduced.length === 0) {
                                pertenece = true
                            }
                        }
                    }
                }
            } else {
                if (trayecto.tramoHora && trayecto.horasIda) {
                    setHorasDisponibles(trayecto.horasIda)
                }
            }
        }
        let todaysDate = new Date()
        todaysDate.setHours(0, 0, 0, 0)

        let dateParsed = new Date(date)
        const beforeToday = dateParsed < todaysDate
        if (!pertenece && !beforeToday) {
            //"2020-08-04T14:58:01.415Z"
            setDateClicked(Moment(date).format())
        } else {
            setOpenDatePopup(true)
        }
    }

    const closeClickDateHandler = () => {
        setOpenDatePopup(false)
        setDateClicked('')
    }

    const closeClickReservarHandler = () => {
        setOpenReservarPopup(false)
    }

    const addVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (trayecto && index) {
            const {auxVis,limite} = addVisitanteGlobal(visitas, trayecto, index, trayecto.prices)
            setLimiteCapacidad(limite)
            if(auxVis !== null) setVisitas(auxVis)
            let auxTot = totalSeats
            auxTot++
            setTotalSeats(auxTot)
        }
    }


    const quitarVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (trayecto && index) {
            const auxVis = quitarVisitanteGlobal(visitas, trayecto, index, trayecto.prices)
            if (auxVis !== null) setVisitas(auxVis)
            setLimiteCapacidad(false)
            let auxTot = totalSeats - 1
            setTotalSeats(auxTot)
        }
    }

    const extrasChangeHandler = (event) => {
        const index = event.currentTarget.value
        if (trayecto && trayecto.extras) {
            const extra = trayecto.extras[index]
            const precioBaseExtra = extra.price
            const precioTotalExtra = extra.price + extra.price * extra.tax.value
            if (event.currentTarget.checked) {
                // Añadir el extra al presupuesto
                let auxExtras = visitas.extras.slice()
                auxExtras.push(extra)
                
                setVisitas({
                    names: visitas.names,
                    indices: visitas.indices,
                    vis: visitas.vis,
                    price: visitas.price,
                    base: visitas.base + precioBaseExtra,
                    total: visitas.total + precioTotalExtra,
                    extras: auxExtras,
                    disabledButtons: visitas.disabledButtons
                })
            } else {
                // Eliminar el extra del presupuesto
                let auxExtras = visitas.extras.slice()
                let indexExtra = -1
                auxExtras.forEach((res) => { if (res.id === extra.id) indexExtra = auxExtras.indexOf(res) })
                auxExtras.splice(indexExtra, 1)
                let total = visitas.total - precioTotalExtra
                if(total < 0){
                    total = 0.00;
                }
                setVisitas({
                    names: visitas.names, indices: visitas.indices, vis: visitas.vis, price: visitas.price, base: visitas.base - precioBaseExtra, total: total, extras: auxExtras, disabledButtons: visitas.disabledButtons
                })
            }
        }
    }

    const horaChangeHandler = (event: any) => {
        const index = event.currentTarget.value
        if (index !== undefined && trayecto) {
            const hora = trayecto.horasIda[index]
            setSelectedHour(hora)
        }
    }

    const addSeatCallback = async ({ row, number, id }: ISeat, addCb: AddCallBackType) => {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 1500))
        const newTooltip = 'Seleccionado'
        addCb(row, number, id, newTooltip)
        setLoading(false)
    }

    const removeSeatCallback = async ({ row, number, id }: ISeat, removeCb: RemoveCallBackType) => {
        setLoading(true)
        await new Promise(resolve => setTimeout(resolve, 1500))
        const newTooltip = null
        removeCb(row, number, newTooltip)
        setLoading(false)
    }

    const trayectoIdaVueltaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(true)
        setSoloIdaClassName(UNCHECKED_CLASSNAME)
        setIdaVueltaClassName(CHECKED_CLASSNAME)
    }

    const trayectoSoloIdaClickHandler = (event: any) => {
        setTrayectoIdaVuelta(false)
        setSoloIdaClassName(CHECKED_CLASSNAME)
        setIdaVueltaClassName(UNCHECKED_CLASSNAME)
    }
 
    const addCart = async () => {
        if (!dateClicked) {
            setOpenDatePopup(true)
        } else {
            setAdding(true)
            amount = (+ visitas.total.toFixed(2)) * 100
            if (trayecto && operationLine/* && bu*/) {
                let tokenTicket = localStorage.getItem('tokenTicket')
                if (!tokenTicket) {
                    tokenTicket = generateTokenticket(20)
                    localStorage.setItem('tokenTicket', tokenTicket)
                }
                               
                // elimino de la venta las categoria de pasajero con 0 pasajeros
                // @ts-ignore
                const visitasSanited: Vis = visitas // visSanited(visitas)
                
                const ticket: any = await mountPayloadStretch(
                    trayecto,
                    dateClicked, 
                    selectedHour, 
                    visitas, 
                    'stretch',
                    publicToken
                )

                const additional = {
                    stretchName: trayecto.name,
                    stretchDescription: trayecto.description,
                    tokenticket: tokenTicket,
                    description: description
                }
                
                ticket.type = TypeTicket.Stretch
                ticket.additional = additional
                ticket.visitas = visitasSanited
                ticket.prices = trayecto.prices
                ticket.tax = trayecto.tax

                const uuid = localStorage.getItem('tokenTicket')
                               
                addStretchToCart(ticket, publicToken).then((res) => {
                    setAdding(false)
                    let message: string = ""
                    let info = false;
                    switch (res.code) {
                        case 200: 
                            if (!uuid) {
                                localStorage.setItem('tokenTicket', res.payload.token)
                            }
                            history.push({
                                pathname: `/${publicToken}/cart/`
                            })
                            break;
                        case 400:
                            message = t("tokenNoExists"); // El token no existe.
                            break
                        case 404:
                            message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                            break
                        case 410:
                            message = t("errorCreateTicket") //"Error al generar el ticket."
                            break
                        case 412:
                            message = t("obtainError") //"Hemos obtenido un error."
                            break
                        case 413:
                            info = true;
                            message = `${ticket.tramoHora ? t("noDisponibility") : 'En este momento no tenemos disponibilidad para el día seleccionado.'}  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                            break
                        case 414:
                            info = true;
                            message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                            break
                        case 415:
                            info = true;
                            message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                            break
                        case 416:
                            info = true;
                            message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                            break
                        case 500:
                            message = t("serverError") //"Error interno del servidor."
                            break
                        default:
                            message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                            break                
                    }
                    if(message !== ""){
                        if(info) {
                            modalInfo(message, t)
                        } else {
                            modalError(message)
                        }     
                    }
                    // Comprobamos si es un número
                    /*if ((/^[0-9]*$/).test(res)) {
                        const statusCode: number = +res
                        let message: string = ""
                        switch (res.code) {
                            case 200: 
                                if (!uuid) {
                                    localStorage.setItem('tokenTicket', res.payload.token)
                                }
                                history.push({
                                    pathname: `/${publicToken}/cart/`
                                })
                                break;
                            case 400:
                                message = t("tokenNoExists"); // El token no existe.
                                break
                            case 404:
                                message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                                break
                            case 410:
                                message = t("errorCreateTicket") //"Error al generar el ticket."
                                break
                            case 412:
                                message = t("obtainError") //"Hemos obtenido un error."
                                break
                            case 413:
                                message = t("notEnoughCapacity") //"No hay aforo suficiente."
                                break
                            case 414:
                                message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                                break
                            case 415:
                                message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                                break
                            case 416:
                                message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                                break
                            case 500:
                                message = t("serverError") //"Error interno del servidor."
                                break
                            default:
                                message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                                break                
                        }
                        if(message !== ""){
                            modalError(message)
                        }

                       /* switch (statusCode) {
                            case 400:
                                message = t("tokenNoExists"); // El token no existe.
                                break
                            case 404:
                                message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                                break
                            case 410:
                                message = t("errorCreateTicket") //"Error al generar el ticket."
                                break
                            case 412:
                                message = t("obtainError") //"Hemos obtenido un error."
                                break
                            case 413:
                                message = t("notEnoughCapacity")//"No hay aforo suficiente."
                                break
                            case 414:
                                message = "El servicio que quiere reservar no está disponible."
                                break
                            case 415:
                                message = "El servicio está deshabilitado para esa fecha."
                                break
                            case 416:
                                message = "El servicio está deshabilitado para esa hora."
                                break
                            case 500:
                                message = "Error interno del servidor."
                                break
                            default:
                                message = "Lo siento, hemos obtenido un error inesperado."
                                break
                        }
                        Sentry.captureException(new Error(`Stretch - ${message}`));
                        modalError(`${t("addCartError")} - ${message}`)
                       // history.push(`/error/${message}/${trayecto.businessUnit.id}`)
                    } else { // En caso de no ser un número, es la url
                        if (!uuid) {
                            localStorage.setItem('tokenTicket', res.uuid)
                        }
                        history.push({
                            pathname: `/${publicToken}/cart/`
                        })
                    }*/
                }).catch(e => {
                    setAdding(false)
                    Sentry.captureException(new Error(`Stretch - Añadir`));
                    modalError(t("errorAddCart"))
                })
            } 

        }
    }

    let disableAddCart = true
    if (trayecto) {
        disableAddCart = !visOK(visitas) || dateClicked === ''
    }

    const images = []
    if (trayecto && trayecto.dataweb) {
        for (let i=1; i<=5; i++) {
            if (trayecto.dataweb[`image${i}`]) {
                const temp = {
                    original: trayecto.dataweb[`image${i}`].replace('http://','https://'),
                    thumbnail: trayecto.dataweb[`image${i}`].replace('http://','https://')
                }
                images.push(temp)
            }

        }
    }
    if(images.length === 0){
        images.push(
            {
                original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
                thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
            })
    }

    return (
        <div className="page-wrapper animated fadeIn">
            <HeaderView onCartClick={() => history.push({
                pathname: `/${publicToken}/cart`,
            }) } publicToken={publicToken}/>
            {
                trayecto && trayecto.businessUnit &&
                <>
                    <HeaderView onCartClick={() => history.push({
                        pathname: `/${publicToken}/cart`,
                    }) } publicToken={publicToken}/>               
                </>                
            }
            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">
                    <div className="row">

                        {
                            !trayecto &&
                            <TourListLoading/>
                        }

                        {
                            trayecto &&
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                        {
                                            disabledCanal && channels !== 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyTMT")}
                                            </div>
                                        }   
                                        {
                                            disabledCanal && channels === 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyGreenpay")}
                                            </div>
                                        }                                                                     
                                <div className="destinations-details__content">
                                    <h3 className="destinations-details__title">{trayecto.name}</h3>
                                    <Carousel
                                        autoPlay
                                        infiniteLoop
                                        showStatus={false}
                                    >
                                        {
                                            images.map((image,index) => {
                                                
                                                return(
                                                    <div>
                                                        <img key={index} src={image.original.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} alt=''/>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    {                                                 
                                        <InfoWeb trayecto = {trayecto} orden={1}/>
                                    }                                  
                                </div>

                            </div>

                        }
                        {
                            trayecto &&
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                                <div className="tour-sidebar">
                                    <div className="tour-sidebar__featured">
                                        <CtaView/>
                                        <div className="fechas">

                                            <CalendarAvailable
                                                disabledDays={trayecto.disabledDays}
                                                horasIda={trayecto.horasIda}
                                                clickDateHandler={clickDateHandler}
                                            />

                                            {
                                                dateClicked !== "" &&
                                                <p className="lead">
                                                    <b>{t("dateSelected")}:</b> {Moment(dateClicked).format("DD/MM/YYYY")}
                                                </p>
                                            }
                                        </div>
                                        <DialogNoDate openDatePopup={openDatePopup} closeClickDateHandler={closeClickDateHandler} t={t}/>
                                        {
                                            // dateClicked && trayecto.horasIda && trayecto.horasIda.length !== 0 &&
                                            dateClicked && trayecto.tramoHora && horasDisponibles && horasDisponibles.length > 0 &&
                                            <div className="horas">
                                                <h3>{t("departureTime")}</h3>
                                                <select className="custom-select" id="hourSelect" onChange={horaChangeHandler} >
                                                    {
                                                        horasDisponibles.map((hora) => {
                                                            const horaString = ((hora.hours.toString().length === 1) ? "0" : "") + hora.hours.toString() + hora.minutes.toString()
                                                            const horaSalida = Moment(horaString, "HHmm").format('HH:mm')
                                                            return (
                                                                <option value={trayecto.horasIda.indexOf(hora)} key={hora.id}>
                                                                    {horaSalida}
                                                                </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        }

                                        {
                                            trayecto.vuelta && trayecto.vueltaPrices.length !== 0 &&
                                            <div className="opciones">
                                                <div>
                                                    <p className="outline-primary" >{t("roundTrip")}</p>
                                                </div>
                                            </div>
                                        }
                                         {
                                            trayecto.hoponHopoff &&
                                            <div className="opciones">
                                                <div>
                                                    <p className="outline-primary" >HoponHopff</p>
                                                </div>
                                            </div>
                                        }
                                        
                                        {
                                            disabled &&
                                            <div>
                                                {t("experienceNotAvailablemsg")}
                                            </div>
                                        }

                                        <Dialog onClose={closeClickReservarHandler} aria-labelledby="customized-dialog-title" open={openReservarPopup} >
                                            <DialogTitle id="customized-dialog-title">Seleccione algún elemento</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>No ha seleccionado ningún elemento y no se puede reservar.</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary" onClick={closeClickReservarHandler} >{t("close")}</button>
                                            </DialogActions>
                                        </Dialog>
                                        <PricesView
                                            prices={trayecto.prices}
                                            money={trayecto.primaryMoney.alphabeticCode}
                                            tax={trayecto.tax}
                                            visitas={visitas}
                                            addVisitantePrices={addVisitantePrices}
                                            quitarVisitantePrices={quitarVisitantePrices}
                                            limiteCapacidad={limiteCapacidad}
                                        />
                                        {
                                            trayecto.extras && trayecto.extras.length !== 0 &&
                                            <ExtrasView trayecto={trayecto} extrasChangeHandler={extrasChangeHandler}/>
                                        }
                                        {
                                            trayecto.seats &&
                                            <>
                                                <SeatPicker
                                                    addSeatCallback={addSeatCallback}
                                                    removeSeatCallback={removeSeatCallback}
                                                    rows={trayecto.seats}
                                                    maxReservableSeats={totalSeats}
                                                    alpha
                                                    visible
                                                    selectedByDefault
                                                    loading={loading}
                                                    tooltipProps={{ multiline: true }}
                                                />
                                            </>
                                        }

                                        <div className="book-form-totals">

                                            <p className='totalAlign'>
                                                <b>Total: </b> {visitas.total.toFixed(2)} {trayecto.primaryMoney.alphabeticCode}
                                            </p>
                                        </div> 
                                        { !adding &&
                                            <button
                                            type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" onClick={addCart} >
                                            {t('addCart')}
                                            </button>
                                        }

                                        {
                                            adding && 
                                            <button
                                            type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" >
                                               <Spinner/>
                                            </button>
                                        }
                                        {                                                 
                                            <InfoWeb trayecto = {trayecto} orden={2}/>
                                        }  
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </section>
        <FooterView/>
        </div>
    )
}