// servicios en colaboración (son de otros operadores que puede vender)
import {getAllData} from '../../Request/Request'

export const addCollaboratorStretchToCart = async (ticket, publicToken) => {
// Control de errores hecho
    let baseURLTicando = process.env.REACT_APP_baseURLTicando
    try{
        const request = await fetch(
            `${baseURLTicando}/tokenticket/addcart3`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "x-provider": "creaticket",
                    "Authorization": `Bearer ${publicToken}`
                },
                credentials: 'include',
                body: JSON.stringify(ticket)
            }
        )
        if(request.status >= 400){
            throw new Error()
        }
        return request.json()
    }catch(e){
        console.log('catch add cart')
        throw e;
    }

}

export const getCollaboratorStretchesData = async (publicToken: string) => {

    // Control de errores hecho
    try{
        const res = await getAllData(publicToken)
        if(res.statusCode === 401){
            return res;
        }
        if(res.statusCode >= 400){
            throw new Error()
        }
        const collaboratorStretches = res.collaboratorStretches;
        return collaboratorStretches
    }catch(e){
        throw e;
    }

}
