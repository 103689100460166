import { primeraLetraMayuscula } from "Modules/Util";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Stretch from 'models/Stretch';
import Extra from "models/Extra";
import { height } from "@mui/system";
import { hexToRgb, rgbToHex } from "@material-ui/core";

interface Props {
    trayecto: Stretch
    extrasChangeHandler(event: any): void
}

export const ExtrasView = ({trayecto, extrasChangeHandler}: Props) => {
    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]); 
    
    return (
        <>
            {
                trayecto.extras && trayecto.extras.length !== 0 &&
                <h3 className="preciosHeading">
                    Extras
                </h3>
            }
            {

                trayecto.extras.map((extra: Extra) => {
                    return (
                        <div className="input-group mb-3" key={extra.id} >
                            <div className="input-group-prepend">
                                <div className="input-group-text">
                                    <input type="checkbox" name={extra.name}
                                        value={trayecto.extras.indexOf(extra)} key={extra.id} id={extra.id.toString()} onChange={extrasChangeHandler} />
                                </div>
                            </div>
                            <div style={{height: 32, borderColor: '#afb8c0', borderRadius: 4, color: 'black', fontSize: 15, minHeight: 47, paddingTop: 8}}className="row row-cols-auto text-left align-items-center">
                              
                              <div>
                              <p style={{margin: 0, fontWeight: 500, width: '100%'}}>{`${extra.name} -  ${(extra.price + extra.price * extra.tax.value).toFixed(2)}  ${trayecto.primaryMoney.alphabeticCode}`}</p>
                                <p style={{marginLeft: 5,margin: 0, fontWeight: 300, fontSize: 11}}>{`${extra.description}`}</p>
                              </div>


                            </div>

                        
                        </div>
                    )
                })
            }
        </>
    )
}