
import ReactDOM from 'react-dom';

import App from './App';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from 'react-i18next';
import i18next from 'i18next';
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';

import './styles/sass/style.scss'
import './styles/sass/style.react.scss'

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
     
const {PUBLIC_URL} = process.env 

Sentry.init({
  dsn: "https://c09d9f2e0e554f939122c68728f38c7a@o1264201.ingest.sentry.io/6446640",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const userLang = window.navigator.language;
let defaultLocale = userLang.substring(0,2);

  // TODO lista de idiomas a codigo duro
  let idiomas = ['es','en'];
if(!idiomas.includes(defaultLocale)){
  defaultLocale = 'en'
}
localStorage.setItem('idioma',defaultLocale)
i18next.init({ 
  interpolation : {escapeValue: false},
  lng: defaultLocale,
  resources: {
    es: {
      global: global_es
    },
    en: { 
      global: global_en
    },
  }
});



ReactDOM.render(

<I18nextProvider i18n={i18next}>
    <App basename={PUBLIC_URL} />
</I18nextProvider>,
document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
