import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@material-ui/core'

import React, {useState, useEffect, useRef} from 'react'
import '../../styles/style.css'
import {useHistory} from 'react-router'
import {RouteComponentProps} from 'react-router'
import {
    addVisitanteGlobal,
    generateTokenticket, getIndexForMapeo, lengths,
    quitarVisitanteGlobal, visOK
} from '../Util'
// import '../../styles/_variables.scss'
// import '../../styles/_calendar.scss'
import {PacketStretchesView} from "./PacketStretchesView"
import {NoFound} from "../../components/NotFound"
import {HeaderView} from "../../components/HeaderView"
import {FooterView} from "../../components/FooterView";
import {CtaView} from "../../components/CtaView";
import {DialogDateSelectView, ISelected} from "../../components/DialogDateSelectView";
import {PricesView} from "../../components/PricesView";
import {round} from "../Util";
import {BasePacket} from '../../models/Packet'
import OperationLine from '../../models/OperationLine'
import BusinessUnit from '../../models/BusinessUnit'
import {IHora} from '../../models/IHora'
import {getOperationLine} from '../OperationLine/Request'
import {addPacketToCart, getCollaboratorPacketByID, getPacketByID} from './Request'
import {Vis} from '../../models/Vis'
import {IDisabledDay} from '../../models/IDisabledDay'
import {ViewApi} from '@fullcalendar/common'
import {TypeTicket} from '../../models/TypetTicket'
import { getChannels } from 'Request/Request'
import { useTranslation } from "react-i18next";
import {Carousel} from "react-responsive-carousel"
import { InfoWeb } from 'components/InfoWeb'
import { modalError } from 'Modules/Payment/Request'
import Spinner from 'reactstrap/es/Spinner'
import * as Sentry from "@sentry/react";

interface PacketParams {
    ticando_id?: string
    packet_id?: string // id de paquete
    operationLine_id?: string
    businessUnit_id?: string
    collaborator_id?: string
    operator_id?: string
    public?: string
}

interface PacketProps extends RouteComponentProps<PacketParams> {
    isCollaboratorPacket?: boolean
}

export const PacketPage: React.FC<PacketProps> = (props: PacketProps) => {

    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        idioma='es';
    }

    const {isCollaboratorPacket} = props
    const packet_id = Number(props.match.params.packet_id)
    const operationLine_id = Number(props.match.params.operationLine_id)
    const operator_id = Number(props.match.params.operator_id)
    const publicToken = props.match.params.public
    const history = useHistory()
    const init: Vis = {names: [], indices: [], vis: [], price: [], base: 0.00, total: 0.00, extras: [], disabledButtons: false}

    const [packet, setPacket] = useState<BasePacket>()
    const [operationLine, setOperationLine] = useState<OperationLine>()
    const [businessUnit, setBusinessUnit] = useState<BusinessUnit>()
    const [channels, setChannels] = useState<any>();
    const [disabledCanal, setDisabledCanal] = useState<boolean>(false)

    const [openDatePopup, setOpenDatePopup] = useState<boolean>(false)
    const [openReservarPopup, setOpenReservarPopup] = useState<boolean>(false)
    const [visitas, setVisitas] = useState<Vis>(init)
    const [totalSeats, setTotalSeats] = useState<number>(0)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [adding, setAdding] = useState<boolean>(undefined);
    const [monedasGreenpay, setMonedasGreenpay] = useState<any>();

    // para gestionar el servico seleccionado para el que se abre el calendario
    const [selected, setSelected] = useState<ISelected>({
        stretchSelected: null,
        indexSelected: null,
        isStretchCollaborator: null
    })

    const [hoursSelected, setHoursSelected] = useState<{ [id: number]: IHora }>({})
    const [datesSelected, setDatesSelected] = useState<{ [id: number]: Date }>({})

    let description = useRef('')
    let canalSi = false;
    
    useEffect(() => {
               if(!channels){
                   getChannels(publicToken).then((res)=>{
                       if(res.msg === 'No TMT'){
                           res = 'notmt';
                       }else if(res.msg === 'GREENPAY'){
                           setMonedasGreenpay(res.monedasDisponibles)
                           res = 'greenpay'
                       }
                       setChannels(res);              
                   })
               }
    }, [channels])

    useEffect(() => {
        i18n.changeLanguage(idioma);  
        if (isCollaboratorPacket) {
            // paquete en colaboracion
            if (!packet) {
                getCollaboratorPacketByID(packet_id, operator_id, publicToken).then(res => {
                    if(res.statusCode === 401){
                        Sentry.captureException(new Error("CollaboratorPacket - Client no encontrado"));
                        history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                        return null;
                    }
                    setPacket(res)
                }).catch(e => {
                    Sentry.captureException(new Error("CollaboratorPacket - getCollaboratorPacketByID"));
                    history.push(`${publicToken}/error/Ha ocurrido un error - getCollaboratorPacketByID`)
                })
            }
        } else {
            //paquete
            if (!operationLine) {
                getOperationLine(operationLine_id, publicToken).then(res => {
                    if(res.statusCode === 401){
                        Sentry.captureException(new Error("Packet - Client no encontrado"));
                        history.push(`/${publicToken}/error/${'Cliente no encontrado'}`)
                        return null 
                    }
                    setOperationLine(res)
                    setBusinessUnit(res.businessUnit)
                }).catch(e => {
                    Sentry.captureException(new Error("Packet - getCollaboratorPacketByID"));
                    history.push(`${publicToken}/error/Ha ocurrido un error - getOperationLine`)
                })
            }
            if (!packet && operationLine) {
                getPacketByID(operationLine, packet_id).then(res => {
                    setPacket(res)
                })
            }
        }

        if (packet && packet.prices) {
            setDisabled(packet.prices.length === 0)
            if (visitas.indices.length===0) { //
                let auxVis: number[] = []
                let auxPrice: number[] = []
                let auxNames: string[] = []
                let indices: number[] = []
                packet.prices.forEach((precio) => {
                    auxVis.push(0)
                    auxPrice.push(0.00)
                    auxNames.push(precio.passengerCategory.name)
                    indices.push(precio.passengerCategory.id)
                })
                setVisitas({
                    names: auxNames,
                    indices,
                    vis: auxVis,
                    price: auxPrice,
                    base: 0.00,
                    total: 0.00,
                    extras: [],
                    disabledButtons: false
                })
            }
            setTotalSeats(0)
        } else {
            setDisabled(true)
        }
        if(packet){
            if(channels && channels !== 'notmt' && channels !== 'greenpay'){
                channels.map(c=>{
                    if(c.currencies === packet.primaryMoney.alphabeticCode){
                        canalSi = true;
                    }
                })
                setDisabledCanal(!canalSi)
            }else if(channels && channels === 'greenpay' && packet){
                let moneda = packet.primaryMoney.alphabeticCode
                if(monedasGreenpay.includes(moneda)){
                    canalSi = true;
                }
                setDisabledCanal(!canalSi)
            }
        }

    }, [packet , operationLine,/*, businessUnit,*/ ,hoursSelected, channels])



    const handleStretchSelected = (event, stretch, index, isStretchCollaborator) => {
        setSelected({
            stretchSelected: stretch,
            indexSelected: index,
            isStretchCollaborator
        })
    }

    const handleChangeHour = (hour: IHora, isStretchCollaborator: boolean, index) => {
        if (!isStretchCollaborator) {
            hoursSelected[index] = hour
            setHoursSelected(prevState => ({
                ...prevState,
                [index]: hour
            }))
        } else {
            hoursSelected[index] = hour
            setHoursSelected(prevState => ({
                ...prevState,
                [index]: hour
            }))
        }
    }

    const closeClickDateHandler = () => {
        setOpenDatePopup(false)
    }

    const closeClickReservarHandler = () => {
        setOpenReservarPopup(false)
    }

    const addVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (packet && index) {
            const {auxVis,limite} = addVisitanteGlobal(visitas, packet, index, packet.prices)
            if (auxVis !== null) setVisitas(auxVis)

            let auxTot = totalSeats
            auxTot++
            setTotalSeats(auxTot)
        } else {
        }
    }

    const quitarVisitantePrices = (event: any) => {
        const index = event.currentTarget.value
        if (packet && index) {
            const auxVis = quitarVisitanteGlobal(visitas, packet, index, packet.prices)
            if (auxVis !== null) setVisitas(auxVis)

            let auxTot = totalSeats - 1
            setTotalSeats(auxTot)
        }
    }


    // compruebo las fechas deshabilitadas
    const someDateDisabled = (packet: BasePacket, datesSelected: {[id: number]: Date}, hoursSelected) => {
        return packet.stretches.reduce((previous, currentStretch) => {
            const index = getIndexForMapeo(currentStretch)
            const result = checkDisabledDay(currentStretch.disabledDays, datesSelected[index], hoursSelected[index])
            return previous || result
        }, false)
    }

    // compruebo si una fecha (sólo dia, mes y año) está en una lista de fechas deshabilitadas
    const checkDisabledDay = (disabledDays: IDisabledDay[], day: Date, hour) => {
        if (!disabledDays || !day) {
            return false
        }
        let indice = disabledDays.findIndex(disableDay =>
            disableDay.year === day.getFullYear() &&
            disableDay.month === day.getMonth() &&
            disableDay.day === day.getDate() 
        )

        if(indice !== -1){
                // Compruebo si el dia seleccionado que está deshabilitado tiene la hora deshabilitada, en ese caso se considera deshabilitado
                let index;
                if(hour !== undefined){
                    index = disabledDays[indice].hours.findIndex(h => h.hours === hour.hours && h.minutes === hour.minutes)
                }else{
                    return true; // indice distinto de -1, es decir, se ha encontrado el dia seleccionado en el array de diasDisabled
                }
                
                return index !== -1
            }else{
                return false;
            }

    }

    // todos servicios con fecha seleccionada
    const datesSelectedOk = (packet: BasePacket, datesSelected: {[id: number]: Date}) => {
        const numberStretches = packet.stretches.length
        const numberCollaboratorStretches = packet.collaboratorStretches.length
        const entriesNumber = Object.entries(datesSelected).length
        return entriesNumber === numberStretches + numberCollaboratorStretches
    }

    // compruebo si a todos los servicios con hora de salida se ha asignado hora
    const hoursSelectedOk = (packet: BasePacket, hoursSelected: {[id: number]: IHora}) => {
        const stretchesWithHorasIda = packet.stretches.reduce((previousValue, currentValue) => {
            if (currentValue.tramoHora && currentValue.horasIda && currentValue.horasIda.length>0) {
                return ++previousValue
            }
            return previousValue
        }, 0)
        const collaboratorStretchesWithHorasIda = packet.collaboratorStretches.reduce((previousValue, currentValue) => {
            if (currentValue.tramoHora && currentValue.horasIda && currentValue.horasIda.length>0) {
                return ++previousValue
            }
            return previousValue
        }, 0)

        const entriesNumber = Object.entries(hoursSelected).length

        return entriesNumber === stretchesWithHorasIda + collaboratorStretchesWithHorasIda
    }

    const addCart = async () => {
        setAdding(true)
        if (packet /*&& operationLine && businessUnit*/) {
            let tokenTicket = localStorage.getItem('tokenTicket')
            if (!tokenTicket) {
                tokenTicket = generateTokenticket(20)
                localStorage.setItem('tokenTicket', tokenTicket)
            }

            const additional = {
                packetName: packet.name,
                packetDescription: packet.description,
                tokenticket: tokenTicket,
                description: description
            }

            const uuid = localStorage.getItem('tokenTicket')

            const netAmount = visitas.base
            let channel;

            let channels = await getChannels(publicToken)

            if(channels.msg){
                channel = {id: 0}; // Pongo 0 si estamos en otra pasarela de pago que no es tmt
            } else {
                channel = channels.filter(c=>packet.primaryMoney.alphabeticCode === c.currencies);
                channel = channel[0] 
            }
            
            const ticket = {
                numero: generateTokenticket(isCollaboratorPacket? lengths.collaboratorPacket: lengths.packet),
                type: isCollaboratorPacket? TypeTicket.CollaboratorPacket : TypeTicket.Packet,
                // packet_id: packet.id,
                // packetName: packet.name,
                // operationLine_id: packet.operationLine.id,
                // operationLineName: packet.operationLine.name,
                // businessUnitName: packet.operationLine.businessUnit.name,
                // taxName: packet.tax.name,
                // tin: businessUnit.tin,
                netAmount: netAmount,
                taxAmount: netAmount * packet.tax.value,
                total: round(visitas.total),
                // user_id: 0,
                // userName: 'web,
                // businessUnit_id: packet.operationLine.businessUnit.id,
                // taxValue: packet.tax.value,
                // payments: [{
                //   method: 'card',
                //   afiliate_id: null,
                //   afiliateName: null,
                //   afiliateTypeCommision: null,
                //   afiliateCommission: null,
                //   amount: round(visitas.total)
                // }],
                // money: packet.money,
                // hasCollaboratorTickets: packet.collaboratorStretches && packet.collaboratorStretches.length>0,

                packet,
                hoursSelected,
                datesSelected,
                idaVueltaSelected: {},
                additional,
                uuid: uuid || undefined,
                visitas,
                aditionalInfo: packet.aditionalInfo,
                money : packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'EURcodigo',
                channel_id: channel.id,
                urlImage: packet.dataweb ? packet.dataweb.image1 : 'https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg',          
            }

            addPacketToCart(ticket, publicToken).then(res => {
                setAdding(false)
                let message: string = "";
                let info 
                switch (res.code) {
                    case 200: 
                        if (!uuid) {
                            localStorage.setItem('tokenTicket', res.payload.token)
                        }
                        history.push({
                            pathname: `/${publicToken}/cart/`
                        })
                        break;
                    case 400:
                        message = t("tokenNoExists"); // El token no existe.
                        break
                    case 404:
                        message = t("stretchInCart") //'Ya tiene agregada esa experiencia al carrito, si quiere modificarla tendrá que eliminarla primero del carrito.'
                        break
                    case 410:
                        message = t("errorCreateTicket") //"Error al generar el ticket."
                        break
                    case 412:
                        message = t("obtainError") //"Hemos obtenido un error."
                        break
                    case 413:
                        info = true;
                        message = `En este momento no tenemos disponibilidad para el día seleccionado.  ${t("thenDisponibility")}`  //t("notEnoughCapacity") //"No hay aforo suficiente."
                        break
                    case 414:
                        info = true;
                        message =  t("serviceNotAvailable") //"El servicio que quiere reservar no está disponible."
                        break
                    case 415:
                        info = true;
                        message = t("dateDisabled") //"El servicio está deshabilitado para esa fecha."
                        break
                    case 416:
                        info = true;
                        message = t("hourDisabled") //"El servicio está deshabilitado para esa hora."
                        break
                    case 500:
                        message = t("serverError") //"Error interno del servidor."
                        break
                    default:
                        message = t("Sorry, we got an unexpected error.") //"Lo siento, hemos obtenido un error inesperado."
                        break                
                }
                if(message !== ""){
                    Sentry.captureException(new Error(`CollaboratorStretch - ${message}`));
                    modalError(message)
                }
                /*history.push({
                    pathname: `/${publicToken}/cart/`,
                    search: publicToken
                })*/
            }).catch(e => {
                setAdding(false)
                Sentry.captureException(new Error("Packet - Añadir"));
                modalError(t("errorAddCart"))
            })
        }
    }

    const renderDay = (backgroundColor, color, content, opacity) =>
        `<div style="display: flex; flex-direction: row; justify-content: center; align-items: center; background-color: ${backgroundColor}; opacity: ${opacity}; height: 100%" >
    <p style="color: ${color}; font-size: 0.7em">${content}</p>
  </div>`


    const handleDayRender = (info: {
        view: ViewApi
        date: Date
        allDay?: boolean
        el: HTMLElement
    }, disabledDays) => {
        const now = new Date()
        info.date.setHours(23, 59, 59, 999)
        const day = new Date(info.date)

        if (day < now) return
        let othermonth = false
        if (info.el.className.includes('fc-disabled-day') || info.el.className.includes('fc-other-month')) othermonth = true

        const disabledDay = disabledDays.find(value => value.day === info.date.getDate() && value.month === info.date.getMonth() && value.year === info.date.getFullYear()
        )

        let color = '#ffcece',
            backColor = 'red',
            content = 'no disponible'

        if (!disabledDay) {
            color = '#eaffdd'
            backColor = 'green'
            content = 'disponible'
        }
        const opacity = othermonth ? 0.3 : 1
        info.el.innerHTML = renderDay(color, backColor, content, opacity)
    }

    let disableAddCart = true

    if (packet) {
        disableAddCart = someDateDisabled(packet, datesSelected,hoursSelected) ||
            !hoursSelectedOk(packet, hoursSelected) ||
            !datesSelectedOk(packet, datesSelected) || !visOK(visitas)

    }


    const images = []
    if (packet && packet.dataweb) {
        for (let i=1; i<=5; i++) {
            if (packet.dataweb[`image${i}`]) {
                if(packet.dataweb[`image${i}`] !== " "){
                    const temp = {
                        original: packet.dataweb[`image${i}`].replace('http://','https://'),
                        thumbnail: packet.dataweb[`image${i}`].replace('http://','https://')
                    }
                    images.push(temp)
                }               
            }
        }
    }
    if(images.length === 0){
        images.push(
            {
                original: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg",
                thumbnail: "https://res.cloudinary.com/marketingpyme/image/upload/w_540,h_300,c_fill/v1639043362/civitrip/plugin/sinImagen.jpg"
            })
    }

    return (
        <div className="page-wrapper animated fadeIn">
            {
                packet && 
                <>
                    <HeaderView onCartClick={() => history.push({
                        pathname: `/${publicToken}/cart`,
                    }) } publicToken={publicToken}/>                
                </>
            }
            <section className="page-header tour-two tour-list destinations-details">
                <div className="container">
                    <div className="row">

                        {
                            !packet &&
                            <NoFound/>
                        }

                        {
                            packet &&
                            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-8">
                                        {
                                            disabledCanal && channels !== 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyTMT")}
                                            </div>
                                        }   
                                        {
                                            disabledCanal && channels === 'greenpay' &&
                                            <div style={{backgroundColor:'red', color: 'white'}}>
                                                {t("noMoneyGreenpay")}
                                            </div>
                                        }  
                             <div className="destinations-details__content">
                                    <h3 className="destinations-details__title">{packet.name}</h3>
                                    <Carousel
                                        autoPlay
                                        infiniteLoop
                                        showStatus={false}
                                    >
                                        {
                                            images.map(image => {
                                                return(
                                                    <div>
                                                        <img src={image.original.replace("w_250,h_200,c_fill","w_450,h_350,c_fill")} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                    {                                                 
                                        <InfoWeb trayecto = {packet} orden={1}/>
                                    }                                  
                                </div>
  
                            </div>
                        }

                        {
                            packet &&
                            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-4">
                                <div className="tour-sidebar">
                                    <div className="tour-sidebar__featured">
                                        <CtaView/>
                                        <>
                                            <DialogDateSelectView
                                                selected={selected}
                                                handleDayRender={handleDayRender}
                                                handleSetOpenDatePopup={(value) => setOpenDatePopup(value)}
                                                handleDatesSelelected={(dateSelected, index, isStretchCollaborator) => {
                                                    if (!isStretchCollaborator) {
                                                        setDatesSelected(prevState => ({
                                                            ...prevState,
                                                            [index]: dateSelected
                                                        }))
                                                    } else {
                                                        setDatesSelected(prevState => ({
                                                            ...prevState,
                                                            [index]: dateSelected
                                                        }))
                                                    }
                                                    setSelected(prevState => ({
                                                        ...prevState,
                                                        stretchSelected: null
                                                    }))
                                                }}
                                                handleDialogActions={() => setSelected(prevState => ({
                                                    ...prevState,
                                                    stretchSelected: null
                                                }))}
                                                t={t}
                                            />
                                        </>

                                        <div className="fechas">
                                            {/*Servicios que incluye el paquete*/}
                                            <div className="col-lg-12">
                                                {/*servicios propios*/}
                                                <PacketStretchesView
                                                    packet={packet}
                                                    isStretchCollaborator={false}
                                                    hoursSelected={hoursSelected}
                                                    datesSelected={datesSelected}
                                                    disabledDate={false}
                                                    handleStretchSelected={handleStretchSelected}
                                                    handleChangeHour={handleChangeHour}
                                                    t={t}
                                                />

                                                {/*servicios en colaboracion*/}
                                                <PacketStretchesView
                                                    packet={packet}
                                                    isStretchCollaborator
                                                    hoursSelected={hoursSelected}
                                                    datesSelected={datesSelected}
                                                    disabledDate={false}
                                                    handleStretchSelected={handleStretchSelected}
                                                    handleChangeHour={handleChangeHour}
                                                    t={t}
                                                />
                                            </div>

                                        </div>

                                        <Dialog onClose={closeClickDateHandler} aria-labelledby="customized-dialog-title"
                                                open={openDatePopup}>
                                            <DialogTitle id="customized-dialog-title">{t("dateNotAvailable")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("dateNotAvailablemsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary"
                                                        onClick={closeClickDateHandler}>{t("close")}
                                                </button>
                                            </DialogActions>
                                        </Dialog>
                                        <PricesView
                                            prices={packet.prices}
                                            money={packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'alphabeticCode'}
                                            tax={packet.tax}
                                            visitas={visitas}
                                            addVisitantePrices={addVisitantePrices}
                                            quitarVisitantePrices={quitarVisitantePrices}
                                        />

                                        {
                                            disabled &&
                                            <div>
                                                {t("experienceNotAvailablemsg")}
                                            </div>
                                        }
                                     

                                        <Dialog onClose={closeClickReservarHandler} aria-labelledby="customized-dialog-title"
                                                open={openReservarPopup}>
                                            <DialogTitle id="customized-dialog-title">{t("selectItem")}</DialogTitle>
                                            <DialogContent dividers>
                                                <Typography gutterBottom>
                                                    <b>{t("selectItemmsg")}</b>
                                                </Typography>
                                            </DialogContent>
                                            <DialogActions>
                                                <button type="button" className="btn btn-primary"
                                                        onClick={closeClickReservarHandler}>{t("close")}
                                                </button>
                                            </DialogActions>
                                        </Dialog>

                                        <div className="book-form-totals">

                                            <p className='totalAlign'>
                                                <b>Total: </b> {visitas.total.toFixed(2)} {packet.primaryMoney ? packet.primaryMoney.alphabeticCode : 'packet.primaryMoney.alphabeticCode' }
                                            </p>
                                        </div>


                                        {
                                            !adding &&
                                            <button
                                            type="button"
                                            disabled={disableAddCart || disabledCanal}
                                            className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled"
                                            onClick={addCart}>{t("addCart")}
                                            </button>    
                                        }
                                        {
                                            adding && 
                                            <button
                                            type="button" disabled={disableAddCart || disabled || disabledCanal} className="btn-huge btn-wide btn-blue test-add-to-cart-action-enabled" >
                                               <Spinner/>
                                            </button>
                                        }
                                        {                                                 
                                            <InfoWeb trayecto = {packet} orden={2}/>
                                        } 
                                    </div>
                                </div>
                            </div>

                        }

                    </div>
                </div>
            </section>
            <FooterView/>
        </div>
    )
}
