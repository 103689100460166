import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { FooterView } from './FooterView';
import { HeaderView } from './HeaderView';

interface ErrorPageParams {
    message: string;
    public: string
}

type ErrorPageProps = RouteComponentProps<ErrorPageParams>;

export const ErrorPage: React.FC<ErrorPageProps> = (props: ErrorPageProps) => {
    const message = props.match.params.message;
    const publicToken = props.match.params.public;
    const history = useHistory();
    return(

        <div className="page-wrapper animated fadeIn">
            <HeaderView onCartClick={() => history.push({
            pathname: `/${publicToken}/cart`,
            })} publicToken={publicToken}/>           

            <section className="page-header tour-two tour-list destinations-details text-center">
                <div className="container">
                    <div className="tour-sidebar">
                        <div className="tour-sidebar__featured pagoIncorrecto">

                            <div className="svg-box">
                                <svg className="circular red-stroke">
                                    <circle className="path" cx="75" cy="75" r="50" fill="none" strokeMiterlimit={10} strokeWidth={5}/>
                                </svg>
                                <svg className="cross red-stroke">
                                    <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                        <path className="first-line" d="M634.087,300.805L673.361,261.53"
                                              fill="none"/>
                                    </g>
                                    <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                        <path className="second-line" d="M634.087,300.805L673.361,261.53"/>
                                    </g>
                                </svg>
                            </div>
                            <h1>Error</h1>
                            <div>
                                <p className="mensajeError">{message}</p>
                                <p>Contacte con el administrador de la página.</p>
                                {/*<p>Por favor, vuelva a intentarlo de nuevo.</p>
                                   */}<p> Gracias y disculpe las molestias</p> 
                                <br />
                            </div>
                        </div>
                    </div>
                    <div>



                    </div>
                </div>
            </section>
        <FooterView/>

        </div>
    );
}

