import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const CtaView = () => {
    const [t,i18n] = useTranslation("global");
    let idioma= localStorage.getItem('idioma');
    if(idioma == null){
        const userLang = window.navigator.language;
        idioma = userLang.substring(0,2);
    }

    useEffect(() => {
        i18n.changeLanguage(idioma);  
    },[idioma]); 

    return (
        <div className="book-online-cta">
            <h2>{t("bookingOnline")}</h2> 
            <ul>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        id="icon-clock"
                        className="icon-clock"
                        width="13"
                        height="13"
                        viewBox="0 0 13 13"
                    >
                        <path
                            d="M6.47,0a6.47,6.47,0,1,0,6.47,6.47h0A6.47,6.47,0,0,0,6.47,0m0,11.5a5,5,0,1,1,5-5,5,5,0,0,1-5,5m2.16-5H7.19V3.59a.72.72,0,1,0-1.44,0v3.6a.72.72,0,0,0,.72.72H8.63a.72.72,0,0,0,0-1.44"/>
                    </svg> 
                    {t("availability")}
                </li>
                <li>
                    <svg
                        xmlns="http://www.w3.org/2000/svg" aria-hidden="true" id="icon-bolt"
                        className="icon-bolt"
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                    >
                        <path
                            d="M8.88,5.91a.88.88,0,0,0-.79-.39H6V.82A.77.77,0,0,0,5.53.05a.8.8,0,0,0-.85.21l-4.57,7a.88.88,0,0,0,0,.87.88.88,0,0,0,.79.39H3l.12,4.7a.78.78,0,0,0,.45.77.86.86,0,0,0,.27.05.83.83,0,0,0,.58-.26l4.42-6.9,0-.06A.88.88,0,0,0,8.88,5.91Z"/>
                    </svg>
                    {t("confirmation")}
                </li>
            </ul>
        </div>
    )
}
