import Cart from "models/Cart";
import { isCartCollaboratorPacketTicket, isCartCollaboratorTicket, isCartPacketTicket, isCartTicket } from "Modules/Cart/CartItem";
import { PaymentGateway } from "./paymentGateway";

const environment = process.env.REACT_APP_ENVIRONMENT
const local = process.env.REACT_APP_LOCAL

export  class Stripe extends PaymentGateway{
    set_url_post(any: any): void {
      throw new Error("Method not implemented.");
    }
    set_url_ok(any: any): void {
      throw new Error("Method not implemented.");
    }
    set_url_ko(any: any): void {
      throw new Error("Method not implemented.");
    }

    publicToken: string;
    urlBasePost: string;
    namePasarela: string;
    totalAmount: number;
    money: string;
    tokenTicket: string;
    name: string;
    surname: string;
    email: string;
    cart: Cart;
    clientSecret:any;
    description: any;
    phone: any;
    additional_info: any
    no_error: any
    error_message: any

    constructor(tokenTicket:string, publicToken: string,  totalAmount: number, description: string, name: string, surname: string, telephone: string, email: string, additional_info: any, money: string, cart: Cart,t: any) {
        super();
        this.publicToken = publicToken;
        this.namePasarela = 'STRIPE';
        this.totalAmount = totalAmount;
        this.money = money;
        this.tokenTicket = tokenTicket;
        this.name = name;
        this.surname = surname;
        this.email = email;
        this.cart = cart;
        this.description = description;
        this.phone = telephone;
        this.additional_info = additional_info;
      }

    async pay(): Promise<any> {
    // Create PaymentIntent as soon as the page loads
    //const stripePromise = loadStripe("pk_test_51KyAptAIUUsdAsyQos1ZBVGllFklz8p61C1fBilg4scS2ca3fYPMUTXIHU16VQMKviCjHQRxlyHCbItePq953xKO00rTw0FzPG");
    const baseURL = process.env.REACT_APP_baseURL
    const prefix = process.env.REACT_APP_PREFIX;
    let clientSecret;
    let productos = []
    let tickets = [...this.cart.cartTickets,...this.cart.cartPacketTickets, ... this.cart.cartCollaboratorTickets, ...this.cart.cartCollaboratorPacketTickets]

    tickets.forEach(t => {
      let name;
      // COMPROBACION SI ISCARTTICKET, ISCArtPACKETTICKEt
      if (isCartTicket(t) || isCartCollaboratorTicket(t)) {
        name = t.ticket.stretchName
      }else if (isCartPacketTicket(t) || isCartCollaboratorPacketTicket(t)) {
        name = t.packetTicket.packet.name
      }
      let aux = {
        id: name,
      }
      productos.push(aux)
    })
    let body = {
      items: productos,
      amount: this.totalAmount*100,
      currency: this.money,
      description: `Ticando Order - ${this.tokenTicket}`,
      name: this.name,
      phone: this.phone,
      additional_info: this.additional_info
    }


    let request = await fetch(`${baseURL}${prefix}/create-payment-intent`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${this.publicToken}` },
      body: JSON.stringify(body),
    })

    let data = await request.json();
    if(!data.statusCode || data.statusCode < 400){
      clientSecret = data.clientSecret;
      this.no_error = true;
    }else{
      this.no_error = false;
      this.error_message = data.message
    }
    
      /*.then((res) => {
        console.log('res create ',res)
        return res.json()})
      .then((data) => {
        console.log('data ',data)
        console.log(data.statusCode)
        if(!data.statusCode || data.statusCode < 400){
          clientSecret = data.clientSecret;
          this.no_error = true;
        }else{
          this.no_error = false;
          this.error_message = data.message
        }
        
      });*/
      const options = {
        clientSecret: clientSecret
      }  

      return {no_error: this.no_error, error_message: this.error_message, options: options, clientSecret: clientSecret}
  };
}